import styles from "./Produk.module.css";
export default function Deposito() {
  return (
    <>
      <div className={`${styles.prodHeader} container-fluid`}>
        <p>Produk &gt; Deposito</p>
        <h1>Deposito</h1>
        <hr />
      </div>
      <div
        className={`${styles.prodDesc} d-flex flex-column flex-lg-row justify-content-lg-evenly`}
      >
        <div className={`${styles.wrap1}`}>
          <h3>Pengertian Deposito</h3>
          <hr />
          <p>
            Deposito adalah penempatan sejumlah dana yang ditempatkan di
            Bank/BPR/BPRS dengan jangka waktu tertentu [1 bulan/3 bulan/6
            bulan/12 bulan]. Umumnya bunga Deposito lebih tinggi dari bunga
            Tabungan dan penempatan dana dalam Deposito Berjangka merupakan
            sarana investasi yang menguntungkan, efektif dan terencana.
            <br />
            <br />
            BPR Arthaguna Sejahtera diawasi oleh Otoritas Jasa Keuangan [OJK]
            dan BPR Arthaguna Sejahtera adalah sebagai peserta dan terdaftar di
            Lembaga Penjamin Simpanan [LPS], sehingga tidak perlu khawatir
            menempatkan dana di BPR Arthaguna Sejahtera; dana yang ditempatkan
            aman, karena penempatan sampai dengan Rp.2.000.000.000,- [duamilyar
            rupiah] dan sesuai dengan syarat dan ketentuan yang ditetapkan;
            dijamin oleh LPS.
            <br />
          </p>
        </div>
        <div className={`${styles.wrap2}`}></div>
      </div>
    </>
  );
}
