// import { useState } from "react";

// import arrow from "../../assets/arrow.svg";

import styles from "./Galeri.module.css";

// HARDCODED UPDATE SOON
import img1 from "../../assets/photos/Galeri/Literasi dan Inklusi Keuangan Akses Keuangan Merata Masyarakat Sejahtera/IMG_20230728_085148.jpg";

export default function Galeri() {
  // const [seeMore, setSeeMore] = useState(false);
  // const placeholdertitle = [
  //   "Penyerahan dan Penyembelihan Hewan Kurban Idul Adha 1441H",
  //   "Studi Banding Yogyakarta",
  //   "Gathering Singapore 2018",
  //   "Pelatihan Analisa Kredit",
  //   "Literasi Tahunan BPR Arthaguna Sejahtera",
  //   "Penyerahan dan Penyembelihan Hewan Kurban Idul Adha 1441H",
  //   "Studi Banding Yogyakarta",
  //   "Gathering Singapore 2018",
  // ];
  return (
    <>
      <div className={`${styles.galeriHead} container-fluid`}>
        <p>Beranda &gt; Galeri</p>
        <h1>Galeri</h1>
        <hr />
      </div>
      <div className={`${styles.galeriBody}`}>
        <div className="row g-4">
          <div
            className="col-12 col-sm-6 col-md-4"
            onClick={() => (window.location = "/galeri/1")}
          >
            <div
              className={`${styles.card} d-flex align-items-end`}
              style={{ backgroundImage: `url(${img1})` }}
            >
              <div
                className={`${styles.info} d-flex flex-column justify-content-end container-fluid`}
              >
                <h5>
                  Literasi dan Inklusi Keuangan Akses Keuangan Merata Masyarakat
                  Sejahtera
                </h5>
                <div>
                  <p className="float-start">10 Images</p>
                  <button className="float-end">See More &gt;</button>
                </div>
              </div>
            </div>
          </div>
          {/* {placeholdertitle.map((title, idx) => {
            return (
              <div
                className="col-12 col-sm-6 col-md-4"
                key={idx}
                onClick={() => (window.location = "/galeri/2")}
              >
                <div className={`${styles.card} d-flex align-items-end`}>
                  <div
                    className={`${styles.info} d-flex flex-column justify-content-end container-fluid`}
                  >
                    <h5>{title}</h5>
                    <div>
                      <p className="float-start">10 Images</p>
                      <button className="float-end">See More &gt;</button>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
          {seeMore ? (
            <>
              <div className="col-12 col-sm-6 col-md-4">
                <div className={`${styles.card} d-flex align-items-end`}>
                  <div
                    className={`${styles.info} d-flex flex-column justify-content-end container-fluid`}
                  >
                    <h5>Gathering Bali 2017</h5>
                    <div>
                      <p className="float-start">10 Images</p>
                      <button className="float-end">See More &gt;</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-4">
                <div className={`${styles.card} d-flex align-items-end`}>
                  <div
                    className={`${styles.info} d-flex flex-column justify-content-end container-fluid`}
                  >
                    <h5>Gathering Bali 2017</h5>
                    <div>
                      <p className="float-start">10 Images</p>
                      <button className="float-end">See More &gt;</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-4">
                <div className={`${styles.card} d-flex align-items-end`}>
                  <div
                    className={`${styles.info} d-flex flex-column justify-content-end container-fluid`}
                  >
                    <h5>Gathering Bali 2017</h5>
                    <div>
                      <p className="float-start">10 Images</p>
                      <button className="float-end">See More &gt;</button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="col-12 d-flex justify-content-center">
                <button
                  id={`${styles.seeMore}`}
                  onClick={() => setSeeMore(true)}
                >
                  Lihat Galeri Lainnya <img src={arrow} alt="arrow" />
                </button>
              </div>
            </>
          )} */}
        </div>
      </div>
    </>
  );
}
