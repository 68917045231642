// import logo from "./logo.svg";

import { BrowserRouter } from "react-router-dom";

import "./App.css";
import LocationProvider from "./LocationProvider";
import RoutesWithAnimation from "./RoutesWithAnimation";

function App() {
  return (
    <BrowserRouter>
      <LocationProvider>
        <RoutesWithAnimation />
      </LocationProvider>
    </BrowserRouter>
  );
}

export default App;
