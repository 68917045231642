import styles from "./Persyaratan.module.css";

export default function Persyaratan() {
  return (
    <>
      <div className={`${styles.syaratHeader} container-fluid`}>
        <p>Beranda &gt; Laporan Publikasi Keuangan</p>
        <h1>Laporan Publikasi Keuangan</h1>
        <hr />
      </div>
      <div
        className={`${styles.syaratDesc} d-flex flex-column justify-content-lg-evenly`}
      >
        <p>
          Anda berniat mengajukan pinjaman ke PT. BPR Arthaguna Sejahtera (BPR
          AGS)? Berikut kami sajikan info mengenai syarat dokumen dan data
          jaminan yang perlu anda siapkan sebelum pengajuan.
        </p>
        <br />
        <p>
          <b>
            Ada 3 kategori calon debitur, yaitu pegawai, professional dan
            wiraswasta.
          </b>
        </p>
        <br />
        <table className="table table-bordered">
          <thead>
            <tr>
              <th scope="col">No</th>
              <th scope="col">Syarat Dokumen</th>
              <th scope="col">Pegawai</th>
              <th scope="col">Profesional</th>
              <th scope="col">Wiraswasta</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>Asli formulir aplikasi diisi dengan lengkap dan benar</td>
              <td>YA</td>
              <td>YA</td>
              <td>YA</td>
            </tr>
            <tr>
              <td>2</td>
              <td>Copy KTP Pemohon & (Suami/Istri)</td>
              <td>YA</td>
              <td>YA</td>
              <td>YA</td>
            </tr>
            <tr>
              <td>3</td>
              <td>Copy Kartu Keluarga (KK)</td>
              <td>YA</td>
              <td>YA</td>
              <td>YA</td>
            </tr>
            <tr>
              <td>4</td>
              <td>Copy Surat Nikah/Cerai (bagi yang telah menikah/Cerai)</td>
              <td>YA</td>
              <td>YA</td>
              <td>YA</td>
            </tr>
            <tr>
              <td>5</td>
              <td>Copy rekening koran/tabungan 3 bulan terakhir</td>
              <td>YA</td>
              <td>YA</td>
              <td>YA</td>
            </tr>
            <tr>
              <td>6</td>
              <td>Copy NPWP Pribadi</td>
              <td>YA</td>
              <td>YA</td>
              <td>YA</td>
            </tr>
            <tr>
              <td>7</td>
              <td>
                Asli Slip Gaji terakhir/Surat keterangan penghasilan dan surat
                keterangan jabatan
              </td>
              <td>YA</td>
              <td>TIDAK</td>
              <td>TIDAK</td>
            </tr>
            <tr>
              <td>8</td>
              <td>Copy Akta Pendirian Perusahaan dan ijin-ijin Usaha</td>
              <td>TIDAK</td>
              <td>TIDAK</td>
              <td>YA</td>
            </tr>
            <tr>
              <td>9</td>
              <td>Copy ijin-ijin praktek profesi</td>
              <td>TIDAK</td>
              <td>YA</td>
              <td>TIDAK</td>
            </tr>
            <tr>
              <td>10</td>
              <td>Copy neraca laba rugi/informasi keuangan terakhir</td>
              <td>TIDAK</td>
              <td>YA</td>
              <td>YA</td>
            </tr>
            <tr>
              <td>11</td>
              <td>
                Copy dokumen kepemilikan agunan : BPKB,STNK (HIDUP),Esek Rangka
                & Mesin
              </td>
              <td>YA</td>
              <td>YA</td>
              <td>YA</td>
            </tr>
            <tr>
              <td>12</td>
              <td>
                Copy dokumen kepemilikan agunan : SHM/SHGB,IMB & STTSPBB
                Terakhir
              </td>
              <td>YA</td>
              <td>YA</td>
              <td>YA</td>
            </tr>
          </tbody>
        </table>
        <br />
        <p>
          <b>
            Adapun Syarat Data Jaminan untuk Kredit dengan Sertifikat atau BPKB
            yaitu:
          </b>
        </p>
        <br />
        <p>
          <b>Data Jaminan Sertifikat SHM/SHGB/SHGU</b>
        </p>
        <ul>
          <li>a/n Pemohon</li>
          <li>a/n Suami atau Istri</li>
          <li>a/n Orang tua dan hanya mempunyai anak tunggal</li>
          <li>Kondisi jaminan tidak masuk mobil maximal 50 juta</li>
          <li>
            Untuk proses ahli waris hanya satu orang tidak bisa lebih dan
            apabila telah disetujui komite pemohon mengurus proses ahli waris
            sampai kecamatan dengan sendirinya.
          </li>
        </ul>
        <br />
        <p>
          <b>Kondisi Jaminan Sertifikat SHM/SHGB/SHGU</b>
        </p>
        <ul>
          <li>Tidak dekat makam</li>
          <li>Tidak dekat dengan sungai atau kali</li>
          <li>Tidak dibawah SUTET</li>
          <li>Ada jalan umum bukan jalan keluarga</li>
          <li>Kondisi jaminan tidak helikopter</li>
        </ul>
        <br />
        <p>
          <b>Data Jaminan BPKB Motor / Mobil</b>
        </p>
        <ul>
          <li>a/n Pemohon</li>
          <li>Ada kwitansi jual beli apabila bukan nama pemohon</li>
          <li>Ada faktur pembelian</li>
          <li>Maximal plafon motor 20 juta</li>
          <li>Untuk motor minimal Tahun 2010 (tergantung merk dan kondisi)</li>
          <li>Maximal plafon mobil 100 juta</li>
          <li>Untuk mobil minimal Tahun 1988 (tergantung merk dan kondisi)</li>
        </ul>
        <br />
        <p>
          Itulah syarat dokumen dan data jaminan yang perlu anda siapkan sebelum
          pengajuan. Mudahkan? Anda butuh pinjaman? Hubungi saja BPR AGS. Proses
          cepat, syarat mudah, bunga ringan.
        </p>
      </div>
    </>
  );
}
