import { useParams } from "react-router-dom";
import styles from "./Karir.module.css";

export default function KarirDetail() {
  const id = useParams();
  console.log(id);

  return (
    <>
      <div className={`${styles.karirHead}`}>
        <p>Beranda &gt; Karir</p>
        <h1>Karir</h1>
        <hr />
      </div>
      <div className={`${styles.karirBody}`}>
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="lowongan">
              <h4>Detail Lowongan</h4>
              <hr />
              <div className="row">
                <p className="col-5 col-md-4">Nama Jabatan</p>
                <p className="col-7 col-md-8">: Account Officer</p>
                <p className="col-5 col-md-4">Atasan Langsung</p>
                <p className="col-7 col-md-8">: Team Leader Marketing</p>
                <p className="col-5 col-md-4">Usia</p>
                <p className="col-7 col-md-8">: 20-30 tahun</p>
                <p className="col-5 col-md-4">Posisi Jabatan</p>
                <p className="col-7 col-md-8">: Level 3</p>
                <p className="col-5 col-md-4">Jenis Kelamin</p>
                <p className="col-7 col-md-8">: Pria/WanitaStrata</p>
                <p className="col-5 col-md-4">Pendidikan</p>
                <p className="col-7 col-md-8">: Min D3</p>
                <p className="col-5 col-md-4">Pengalaman Kerja</p>
                <p className="col-7 col-md-8">
                  : minimal 1 tahun di bidang perbankan
                </p>
              </div>
            </div>
            <div className="persyaratan">
              <h4>Persyaratan</h4>
              <hr />
              <p>
                <b>Syarat Fisik Khusus:</b> <br />
              </p>
              <ol>
                <li>Penampilan Menarik</li>
                <li>Proporsional</li>
              </ol>
              <p>
                <b>Kompetisi khusus:</b>
              </p>
              <ol>
                <li>Skala Kompetensi Manajerial 3</li>
                <li>Domisili di wilayah kerja Minimum 2 tahun</li>
                <li>Data Nasabah Minimum 10 orang</li>
              </ol>
              <p>
                <b>Syarat lainnya:</b>
              </p>
              <ol>
                <li>Mempunyai kendaraan sendiri</li>
                <li>Bersedia bekerja diluar jam kantor</li>
              </ol>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="pelatihan">
              <h4>Pelatihan</h4>
              <hr />
              <ol>
                <li>Selling & Communication Skills</li>
                <li>Strategi Marketing</li>
                <li>Analisa Kredit</li>
                <li>APU PPT</li>
                <li>Perlindungan Konsumen</li>
                <li>Appraisal Agunan</li>
                <li>Manajemen NPL & Penyelesaian Kredit Bermasalah</li>
                <li>Pemantauan dan Maintenance Debitur (Konsultan Usaha)</li>
                <li>Service Excellence</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
