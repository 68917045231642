import styles from "./Tentang.module.css";

export default function VisiMisi() {
  return (
    <>
      <div className={`${styles.tentangHeader} container-fluid`}>
        <p>Tentang &gt; Visi & Misi PT. BPR Arthaguna Sejahtera</p>
        <h1>Visi & Misi PT. BPR Arthaguna Sejahtera</h1>
        <hr />
      </div>
      <div className={`${styles.tentangDesc} ${styles.visiMisi} row gy-4`}>
        <div className="col-12 col-md-2">
          <h1>VISI</h1>
          <hr />
        </div>
        <div className="col-12 col-md-10">
          <p>
            Menjadikan lembaga keuangan / BPR terpercaya yang memberikan
            pembinaan dan pelayanan sepenuh hati bagi setiap nasabah sehingga
            menjadikan jaringan nasabah tersebut dapat ikut serta meningkatkan
            ekonomi nasabah dan masyarakat sekitarnya.
          </p>
        </div>
        <div className="col-12 col-md-2">
          <h1>MISI</h1>
          <hr />
        </div>
        <div className="col-12 col-md-10">
          <ul className="ps-3">
            <li>
              Memberikan produk perbankan yang dapat memberikan peningkatan
              ekonomi nasabah.
            </li>
            <li>Melayani sepenuh hati.</li>
          </ul>
        </div>
      </div>
    </>
  );
}
