import clock from "../../assets/clock.svg";
import message from "../../assets/comment.svg";
import admin from "../../assets/profile.svg";

import styles from "./BeritaDetail.module.css";

import { useParams } from "react-router-dom";

// HARDCODE UPDATE SOON
import ber1 from "../../assets/photos/Berita/berita1.jpg";
import ber2 from "../../assets/photos/Berita/berita2.jpeg";
import ber3 from "../../assets/photos/Berita/berita3.jpeg";
import ber4 from "../../assets/photos/Berita/berita4.jpg";

export default function BeritaDetail() {
  const id = useParams().id;
  console.log(id);

  const beritaList = [
    {
      title:
        "LPS Bakal Luncurkan Sistem IT untuk Awasi BPR, Anggarannya Capai Rp 200 M",
      date: "25 Oktober 2023",
      category: "Uncategorized",
      img: ber1,
      content:
        'Lembaga Penjamin Simpanan () bakal meluncurkan sistem teknologi super canggih untuk mengawasi kegiatan Bank Perekonomian Rakyat () di daerah.  IT itu akan diluncurkan pada Januari 2025.\
      "Kita sedang bangun sistem IT untuk BPR. Sehingga mereka bisa lebih profesional dan kita juga bisa mengawasi mereka secara real time dari waktu ke waktu," kata Ketua Dewan LPS Purbaya Yudhi Sadewa di Indramayu, Rabu (25/10).\
      Purbaya bilang, pihaknya merogoh kocek hingga Rp 200 miliar untuk membangun sistem teknologi super canggih ini. Dia mengaku, sistem keamanan yang ada dalam teknologi ini akan sangat aman bahkan tidak mudah untuk di-hack.\
      "Kita sedang persiapan sampe 2025 diluncurkan. Jangan sampai sistemnya kacau gampang di-hack. Saya jamin LPS IT-nya canggih sekali," tutur Purbaya.Pada September 2023, LPS memutuskan untuk mempertahankan penjaminan rupiah di bank umum serta rupiah di BPR dan simpanan valas di bank umum.Tingkat bunga penjaminan yang berlaku di bank umum untuk rupiah yakni 4,25 persen dan untuk valuta asing (valas) sebesar 2,25 persen. Sedangkan Tingkat Bunga Penjaminan yang berlaku di BPR sebesar 6,75 persen.\
      “RDK LPS menetapkan untuk mempertahankan tingkat bunga rupiah bak umum dan BPR dan valas," kata Purbaya beberapa waktu lalu.\
      Keputusan tersebut diambil setelah mempertimbangkan ketidakpastian global yang masih tinggi untuk menjaga stabilitas keuangan nasional yang mendorong perekonomian nasional.\
      Tingkat bunga mencerminkan batas maksimum tentang perbankan yang ditentukan oleh suku bunga simpanan di industri perbankan.\
      "Selanjutnya tingkat bunga penjamin tersebut akan berlaku untuk periode 1 Oktober 2023 sampai dengan 31 Januari 2024," tandasnya.',
    },
    {
      title: "Gaji Rata-rata Lulusan SD, SMP, SMA, dan S1, Berapa Bedanya?",
      date: "27 Oktober 2023",
      category: "Uncategorized",
      img: ber2,
      content:
        "Jakarta - Tingkat pendidikan sering kali menjadi salah satu tolak ukur besaran gaji yang diterima para pekerja. Biasanya semakin tinggi tingkat pendidikan yang dimiliki, semakin besar juga gaji yang bisa mereka terima.\
      Berdasarkan data rata-rata gaji bersih pekerja menurut pendidikan akhir yang dirilis Badan Pusat Statistik (BPS) 2023, gaji rata-rata pekerja di Indonesia untuk semua tingkat pendidikan sebesar Rp 2.944.541 per bulan.\
      Nilai ini mengalami penurunan bila dibandingkan data BPS pada Desember 2022 lalu, di mana gaji rata-rata pekerja di Indonesia untuk semua tingkat pendidikan sebesar Rp 3.070.756.\
      Berdasarkan data terakhir yang dikeluarkan BPS pada Juni 2023 lalu, secara keseluruhan para pekerja yang tidak/belum pernah sekolah memiliki rata-rata gaji sebesar Rp 1.567.516 per bulan. Rata-rata gaji terendah berada di sektor industri pengolahan sebesar Rp1.108.365 dan tertinggi di sektor konstruksi sebesar Rp2.554.083.\
      Kemudian, untuk pekerja tamatan SD rata-rata gaji yang bisa mereka terima sebesar Rp1.986.400 per bulan. Sedangkan untuk upah rata-rata bulanan para pekerja yang pernah bersekolah hingga SD namun tidak tamat berada di Rp 1.633.406 setiap bulan\
      Selanjutnya pada tingkat pendidikan akhir sekolah menengah, BPS mencatat upah untuk lulusan SMP memiliki rata-rata gaji sebesar Rp 2.235.533 setiap bulannya. Sedangkan untuk lulusan SMA sebesar Rp 2.790.116 dan SMK sebesar Rp 2.931.141 per bulannya.\
      Di atas itu, pekerja dengan tingkat pendidikan akhir diploma (I/II/III/Akademi) memperoleh upah per bulan rata-rata sebesar Rp 3.727.710. Lalu, upah bulanan para pekerja lulusan universitas atau minimal sarjana (S1) berada di level Rp 4.460.185.\
      Perlu diingat besaran upah/gaji ini bersifat rata-rata nasional. Tentu pada akhirnya banyak faktor lain yang dapat menentukan besaran gaji yang dapat diterima seorang pekerja.\
      Faktor-faktor lainnya itu bisa berupa kebijakan tempat pemberi kerja, tingkat UMP/UMK tempat bekerja, bidang pekerjaan, dan masih banyak lagi. Namun tidak bisa dipungkiri juga bila tingkat pendidikan akhir bisa sangat berpengaruh pada besaran gaji yang bisa diterima.",
    },
    {
      title: "BI Rate Naik, Bagaimana Dampaknya pada Investasi Masyarakat?",
      date: "27 Oktober 2023",
      category: "Uncategorized",
      img: ber3,
      content:
        'Jakarta - Bank Indonesia (BI) resmi menaikan bunga acuannya per 19 Oktober 2023 menjadi 6%, kenaikan yang dilakukan meningkat 0,25% basis poin dari 5,75% sejak Januari 2023. Kebijakan tersebut merupakan bentuk strategis yang dilakukan oleh BI agar memperkuat nilai tukar rupiah dan untuk mengontrol tingkat inflasi.\
      Dari keputusan yang dilakukan BI dalam menaikan bunga acuan, hal ini bisa memberikan dampak yang baik pada iklim investasi yang dimiliki oleh masyarakat pada saat ini. Dari kenaikan tersebut diprediksi akan diikuti juga oleh kenaikan Bunga Penjaminan oleh Lembaga Penjamin Simpanan (LPS).\
      Bunga Bank Umum diprediksi bisa menjadi 4,5% dari sebelumnya 4,25% dan Bunga Bank Perekonomian Rakyat (BPR) menjadi 7% dari sebelumnya 6,75%. Head of Marketing DepositoBPR by Komunal Vera Rosana mengatakan jika kemungkinan Bunga Penjamin LPS benar terjadi dalam waktu dekat, maka bisa menjadi motivasi bagi masyarakat.\
      "Jika prediksi kenaikan Bunga Penjamin LPS ini benar terjadi dalam waktu dekat, maka akan menjadi motivasi bagi masyarakat untuk meningkatkan simpanannya di bank, karena bisa mendapatkan bunga yang juga lebih tinggi, khususnya simpanan masyarakat dalam bentuk deposito," ungkap Vera dalam keterangan tertulis, Jumat (27/10/2023).\
Jika prediksi ini terealisasi, maka bunga keuntungan deposito BPR akan menjadi lebih tinggi dari Kupon Return Obligasi yang mayoritas di sekitaran <6,75% dan Fixed selama rata-rata >3 tahun. Ini diasumsikan masyarakat telah membeli obligasi di waktu sebelum kenaikan dari BI.\
Vera menilai tentunya ini akan mempengaruhi minat masyarakat pada instrumen obligasi, khususnya obligasi pemerintah seperti SBN, ORI, dan yang lainnya karena kini ada pilihan instrumen investasi lain dengan keamanan setara namun menawarkan return yang lebih tinggi.\
Apalagi menjual obligasi di masa sekarang akan menyebabkan capital loss karena imbas kenaikan suku bunga ini.\
Kemudian, instrumen investasi yang juga kemungkinan terdampak adalah Reksadana, khususnya Reksadana Pendapatan Tetap dan Reksadana Pasar Uang. Bunga deposito BPR akan menjadi sangat kompetitif dengan tingkat return RDPT/RDPU yang rata-rata bisa memberikan keuntungan sekitar 7% - 10% per tahun.\
Deposito BPR diprediksi dapat bersaing ketat dengan instrument RDPT/RDPU dilihat dari potensi nilai keuntungan, yang menjadi pembedanya adalah Deposito BPR dapat memberikan keuntungan yang lebih pasti dan keamanan yang lebih terjamin melalui penjaminan dari LPS hingga Rp 2 miliar per nasabah per bank. Pada hal ini masyarakat tidak perlu khawatir investasi yang dimiliki mengalami fluktuasi nilai.\
Vera menjelaskan untuk masyarakat untuk menanggulangi kondisi perubahan BI rate yang tidak bisa diprediksi, penyelesaiannya adalah dengan kemampuan masyarakat dengan pengelolaan risiko dari instrumen yang dimiliki.\
"Solusi terbaik adalah dengan melakukan diversifikasi, don\'t put your egg in one basket. Karena tidak ada instrumen investasi yang paling hebat, yang hebat adalah bagaimana cara masyarakat mengelola risiko dari instrumen investigasi yang dimiliki," jelasnya.\
Vera juga memberikan opsi lain kepada masyarakat bisa menjadikan Deposito BPR menjadi opsi diversifikasi terbaik.\
Selain bisa memberikan keuntungan yang sangat kompetitif dibandingkan instrumen investasi rendah risiko yang lain, deposito BPR dapat memberikan tingkat keuntungan lebih pasti menyesuaikan dengan bunga deposito yang dipilih. Deposito BPR juga secara keamanan lebih terjamin karena sudah mendapat perlindungan penjaminan dari LPS hingga Rp 2 miliar per nasabah per bank.\
Saat ini masyarakat tidak perlu bingung lagi jika ingin melakukan diversifikasi investasi ke produk Deposito BPR, karena sudah ada aplikasi DepositoBPR by Komunal, aplikasi marketplace khusus produk Deposito BPR, di mana sudah terdaftar 200 lebih BPR terseleksi di seluruh Indonesia.\
Jadi masyarakat bisa merasakan dengan mudah untuk melakukan diversifikasi dan merasakan pengalaman yang lebih praktis untuk melakukan penempatan deposito di ratusan BPR tersebut dengan hanya cukup 1 kali daftar melalui aplikasi saja," imbuhnya.\
Masyarakat tidak perlu khawatir karena seluruh BPR tergabung menjadi peserta penjaminan LPS, sehingga sudah aman dan dengan penawaran bunga deposito yang tinggi sesuai ketentuan LPS. Dengan menggunakan aplikasi DepositoBPR by Komunal, masyarakat bisa dengan mudah melakukan diversifikasi ke produk simapan yang sudah jelas keamanannya tetapi dengan penawaran bunga keuntungan yang tinggi layaknya produk investasi.',
    },
    {
      title:
        "Nilai Tukar Rupiah Melemah di Agustus 2023, BI Ungkap Penyebabnya",
      date: "10 September 2023",
      category: "Uncategorized",
      img: ber4,
      content:
        "Labuan Bajo – Ketidakpastian perekonomian yang terjadi di global, memengaruhi pergerakan nilai tukar rupiah pada Agustus 2023.\
      Direktur Departemen Kebijakan Ekonomi dan Moneter Bank Indonesia (BI) Erwindo Kolopaking mengatakan, nilai tukar rupiah per 31 Agustus 2023 melemah sebesar 0,98 persen dibandingkan level akhir Juli 2023.“Peningkatan ketidakpastian pasar keuangan global menyebabkan nilai tukar rupiah pada akhir Agustus 2023 melemah secara point to point,” terang Erwindo, dikutip, Minggu 10 September 2023.\
      Meskipun terjadi penurunan secara bulanan, namun nilai tukar rupiah menguat secara year to date (ytd) atau dibandingkan dengan akhir 2022. Adapun penguatan nilai tukar rupiah secara ytd adalah sebesar 2,22 persen.\
      Hal ini juga lebih baik bila dibandingkan dengan penguatan nilai tukar rupiah negara lain, seperti Rupee India yang sebesar 0,06 persen ytd. Serta, lebih baik dibandingkan dengan penguatan nilai tukar Thailand yang sebesar 1,06 persen ytd dan Filipina yang naik 1,54 persen ytd. Dalam hal ini, BI memastikan akan tetap menjaga pergerakan nilai tukar rupiah. Terlebih, rupiah diyakini akan tetap stabil.\
      “Seiring dengan persepsi positif investor terhadap prospek ekonomi Indonesia, inflasi yang rendah, dan imbal hasil aset keuangan domestik yang menarik,” pungkasnya. (*)",
    },
  ];

  const selectedBerita = beritaList[id - 1];

  return (
    <>
      <div className={`${styles.thumb} container-fluid`}>
        <img src={selectedBerita.img} alt="thumb" />
      </div>
      <div className={`${styles.news} row`}>
        <div className={`${styles.sec1} col-9`}>
          <p id={`${styles.category}`}>
            Berita &gt; {selectedBerita.category} &gt; {selectedBerita.title}
          </p>
          <h2>{selectedBerita.title}</h2>
          <hr />
          <div className={`${styles.detail} d-flex`}>
            <div className={`${styles.time} d-flex align-items-center mx-0`}>
              <img width="17" height="17" src={clock} alt="" />
              <p>{selectedBerita.date}</p>
            </div>
            <div className={`${styles.comment} d-flex align-items-center mx-2`}>
              <img width="17" height="17" src={message} alt="" />
              <p>0</p>
            </div>
            <div className={`${styles.comment} d-flex align-items-center`}>
              <img width="17" height="17" src={admin} alt="" />
              <p>bprgas17</p>
            </div>
          </div>
          <p id={`${styles.report}`}>{selectedBerita.content}</p>
          {/* <div className={`${styles.response}`}>
            <h4>Tinggalkan balasan</h4>
            <form className="row g-3">
              <div className="col-4">
                <input
                  className="form-control"
                  id="inputNama"
                  type="text"
                  placeholder="Nama"
                />
              </div>
              <div className="col-4">
                <input
                  className="form-control"
                  id="inputEmail"
                  type="email"
                  placeholder="Email"
                />
              </div>
              <div className="col-4">
                <input
                  className="form-control"
                  id="inputWeb"
                  type="text"
                  placeholder="Website"
                />
              </div>
              <div className="col-12">
                <textarea
                  className="form-control"
                  id={`${styles.inputKomentar}`}
                  placeholder="Komentar"
                />
              </div>
              <div className="col-12">
                <button className="float-start">Tambahkan Komentar</button>
              </div>
            </form>
          </div> */}
        </div>
        {/* <div className={`${styles.sec2} col-3`}>
          <div className={`${styles.pos}`}>
            <h5>Pos-Pos Terbaru</h5>
            <hr />
            {beritaList.map((berita, idx) => {
              if (berita !== selectedBerita) {
                return (
                  <p
                    onClick={() => (window.location = `/berita/${idx + 1}`)}
                    key={idx}
                  >
                    {berita.title}
                  </p>
                );
              }
            })}
          </div>
          <div className={`${styles.komentar}`}>
            <h5>Komentar Terbaru</h5>
            <hr />
            <p>"Wah menarik banget ya!"</p>
            <p>
              "Menurut saya ini penting, karena masker itu udah menjadi bagian
              dari hidup kita"
            </p>
          </div>
          <div className={`${styles.arsip}`}>
            <h5>Arsip</h5>
            <hr />
            <p>September 2020</p>
            <p>Maret 2020</p>
            <p>September 2019</p>
            <p>Juni 2018</p>
          </div>
          <div className={`${styles.kategori}`}>
            <h5>Kategori</h5>
            <hr />
            <p>Berita BPR AGS</p>
            <p>Peluang Usaha</p>
            <p>Uncategorized</p>
          </div>
          <div className={`${styles.meta}`}>
            <h5>Meta</h5>
            <hr />
            <p>Masuk</p>
            <p>Feed</p>
            <p>Feed komentar</p>
            <p>Wordpress.org</p>
          </div>
        </div> */}
      </div>
    </>
  );
}
