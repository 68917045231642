export default function AddBerita() {
  return (
    <>
      <div className="d-flex flex-column align-items-center">
        <h1>Add Berita</h1>
        <form className="d-flex flex-column flex-md-row">
          <div className="form row gy-3">
            <div className="col-12 ">
              <label htmlFor="inputJudul" className="form-label">
                Judul
              </label>
              <input type="text" id="inputJudul" className="form-control" />
            </div>
            <div className="col-12 ">
              <label htmlFor="inputIsi" className="form-label">
                Isi
              </label>
              <textarea id="inputIsi" className="form-control" />
            </div>
            <div className="col-12 ">
              <label htmlFor="inputThumbnail" className="form-label">
                Thumbnail
              </label>
              <input type="file" id="inputThumbnail" className="form-control" />
            </div>
            <div className="col-12 d-flex justify-content-center">
              <button>Submit</button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
