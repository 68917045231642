import styles from "./Tentang.module.css";
import LogoBPR2 from "../../assets/LogoBPRTentang.png";
export default function Kmas() {
  return (
    <>
      <div className={`${styles.tentangHeader} container-fluid`}>
        <p>Tentang &gt; Pengertian Bank Perkreditan Rakyat (BPR)</p>
        <h1>Pengertian Bank Perkreditan Rakyat (BPR)</h1>
        <hr />
      </div>
      <div
        className={`${styles.tentangDesc} d-flex flex-column flex-lg-row justify-content-lg-evenly`}
      >
        <div className={`${styles.wrap1}`}>
          <p>
            <img src={LogoBPR2} alt="Logo BPR" id={`${styles.bprInline}`} />
            Bank Perkreditan Rakyat (BPR) adalah lembaga keuangan bank yang
            menerima simpanan hanya dalam bentuk deposito berjangka, tabungan,
            dan/atau bentuk lainnya yang dipersamakan dan menyalurkan dana
            sebagai usaha BPR. Dengan lokasi yang pada umumnya dekat dengan
            tempat masyarakat yang membutuhkan. Status BPR diberikan kepada Bank
            Desa, Lumbung Desa, Bank Pasar, Bank Pegawai, Lumbung Pitih Nagari
            (LPN), Lembaga Perkreditan Desa (LPD), Badan Kredit Desa (BKD),
            Badan Kredit Kecamatan (BKK), Kredit Usaha Rakyat Kecil (KURK),
            Lembaga Perkreditan Kecamatan (LPK), Bank Karya Produksi Desa
            (BKPD), dan/atau lembaga-lembaga lainnya yang dipersamakan
            berdasarkan UU Perbankan Nomor 7 Tahun 1992 dengan memenuhi
            persyaratan tatacara yang ditetapkan dengan Peraturan Pemerintah.
            Ketentuan tersebut diberlakukan karena mengingat bahwa
            lembaga-lembaga tersebut telah berkembang dari lingkungan masyarakat
            Indonesia, serta masih diperlukan oleh masyarakat, maka keberadaan
            lembaga dimaksud diakui. Oleh karena itu, UU Perbankan Nomor 7 Tahun
            1992 memberikan kejelasan status lembaga-lembaga dimaksud. Untuk
            menjamin kesatuan dan keseragaman dalam pembinaan dan pengawasan,
            maka persy-ratan dan tatacara pemberian status lembaga-lembaga
            dimaksud ditetapkan dengan Peraturan Pemerintah.
            <br />
            <br />
          </p>
          <h4>Usaha yang dilakukan BPR</h4>
          <hr />
          <p>
            Usaha BPR meliputi usaha untuk menghimpun dan menyalurkan dana
            dengan tujuan mendapatkan keuntungan. Keuntungan BPR diperoleh dari
            spread effect dan pendapatan bunga. Adapun usaha-usaha BPR adalah :
          </p>
          <ul>
            <li>
              Menghimpun dana dari masyarakat dalam bentuk simpanan berupa
              deposito berjangka, tabungan, dan/atau bentuk lainnya yang
              dipersamakan dengan itu.
            </li>
            <li>Memberikan kredit.</li>
            <li>
              Menyediakan pembiayaan bagi nasabah berdasarkan prinsip bagi hasil
              sesuai dengan ketentuan yang ditetapkan dalam Peraturan
              Pemerintah.
            </li>
            <li>
              Menempatkan dananya dalam bentuk Sertifikat Bank Indonesia (SBI),
              deposito berjangka, sertifikat deposito, dan/atau tabungan pada
              bank lain. SBI adalah sertifikat yang ditawarkan Bank Indonesia
              kepada BPR apabila BPR mengalami over liquidity atau kelebihan
              likuiditas.
            </li>
          </ul>
        </div>
        <div className={`${styles.wrap2}`}>
          <h4>Usaha yang tidak boleh dilakukan BPR</h4>
          <hr />
          <p>
            Ada beberapa jenis usaha seperti yang dilakukan bank umum tetapi
            tidak boleh dilakukan BPR. Usaha yang tidak boleh dilakukan BPR
            adalah :
          </p>
          <ul>
            <li>Menerima simpanan berupa giro.</li>
            <li>Melakukan kegiatan usaha dalam valuta asing.</li>
            <li>
              Melakukan penyertaan modal dengan prinsip prudent banking dan
              concern terhadap layanan kebutuhan masyarakat menengah ke bawah.
            </li>
            <li>Melakukan usaha perasuransian.</li>
            <li>
              Melakukan usaha lain di luar kegiatan usaha sebagaimana yang
              dimaksud dalam usaha BPR.
            </li>
          </ul>
          <h4>Alokasi Kredit BPR</h4>
          <hr />
          <p>
            Dalam mengalokasikan kredit, ada beberapa hal yang harus
            diperhatikan oleh BPR, yaitu:
          </p>
          <ul>
            <li>
              Dalam memberikan kredit, BPR wajib mempunyai keyakinan atas
              kemampuan dan kesanggupan debitur untuk melunasi utangnya sesuai
              dengan perjanjian.
            </li>
            <li>
              Dalam memberikan kredit, BPR wajib memenuhi ketentuan Bank
              Indonesia mengenai batas maksimum pemberian kredit, pemberian
              jaminan, atau hal lain yang serupa, yang dapat dilakukan oleh BPR
              kepada peminjam atau sekelompok peminjam yang terkait, termasuk
              kepada perusahaan-perusahaan dalam kelompok yang sama dengan BPR
              tersebut. Batas maksimum tersebut adalah tidak melebihi 30% dari
              modal yang sesuai dengan ketentuan yang ditetapkan Bank Indonesia.
            </li>
            <li>
              Dalam memberikan kredit, BPR wajib memenuhi ketentuan Bank
              Indonesia mengenai batas maksimum pemberian kredit, pemberian
              jaminan, atau hal lain yang serupa, yang dapat dilakukan oleh BPR
              kepada pemegang saham (dan keluarga) yang memiliki 10% atau lebih
              dari modal disetor, anggota dewan komisaris (dan keluarga),
              anggota direksi (dan keluarga), pejabat BPR lainnya, serta
              perusahaan-perusahaan yang di dalamnya terdapat kepentingan pihak
              pemegang saham (dan keluarga) yang memiliki 10% atau lebih dari
              modal disetor, anggota dewan komisaris (dan keluarga), anggota
              direksi (dan keluarga), pejabat BPR lainnya. Batas maksimum
              tersebut tidak melebihi 10% dari modal yang sesuai dengan
              ketentuan yang ditetapkan Bank Indonesia.
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
