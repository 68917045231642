import "./Footer.css";
import LogoBPR from "../assets/LogoBPR.jpg";
import LogoBPR2 from "../assets/LogoBPR2.png";
import LogoOJK from "../assets/LogoOJK.png";
import LogoLPS from "../assets/LogoLPS.png";

export default function Footer() {
  return (
    <div className="footer container-fluid d-flex flex-wrap justify-content-start">
      <div className="wrap-1 d-flex flex-column flex-lg-row">
        <div className="logos d-flex flex-column">
          <img src={LogoBPR} id="logobpr" alt="logobpr" />
          <div className="row">
            <div className="col-8">
              <p>BPR AGS terdaftar & diawasi oleh:</p>
              <img src={LogoOJK} id="logoojk" alt="logoojk" />
              <p>Simpanan BPR AGS dijamin oleh:</p>
              <img src={LogoLPS} id="logolps" alt="logolps" />
            </div>
            <div className="col-4 d-flex align-items-center">
              <img src={LogoBPR2} id="logobpr2" alt="logobpr2" />
            </div>
          </div>
        </div>
        <div className="links d-flex flex-column">
          <h5>Link Penting</h5>
          <hr />
          <a href="/produk/tabungan">Produk Simpanan</a>
          <a href="/persyaratan">Persyaratan</a>
          <a href="/publikasi">Laporan Publikasi</a>
          <a href="https://www.ojk.go.id/id/Default.aspx">Website OJK</a>
          <a href="https://lps.go.id/">Website LPS</a>
        </div>
      </div>
      <div className="wrap-2 d-flex flex-column flex-lg-row ">
        <div className="contacts">
          <h5>Informasi Kontak</h5>
          <hr />
          <p className="text-bold">Kantor Pusat</p>
          <p className="text">
            Ruko Depok Maharaja Blok A1 No.9-10 <br />
            Jl. Raya Sawangan Pancoran Mas <br />
            Depok 16433
          </p>
          <div className="other d-flex">
            <img
              width="30"
              height="30"
              src="https://img.icons8.com/ios-glyphs/30/marker--v1.png"
              alt="marker--v1"
            />
            <a href="/kontak">Lokasi BPR AGS Lainnya</a>
          </div>
        </div>
        <div className="banks">
          <h5>Informasi Rekening</h5>
          <hr />
          <p className="text-bold">BANK MANDIRI</p>
          <p className="text">
            Cab. Margonda Depok <br />
            No.Rek : 157 0000 374 026 <br />
            a.n. PT BPR Arthaguna Sejahtera
          </p>
          <p className="text-bold">BANK CENTRAL ASIA</p>
          <p className="text">
            Cab.Margonda Depok
            <br />
            No.Rek : 869 004 7749
            <br />
            a.n. PT BPR Arthaguna Sejahtera
          </p>
        </div>
      </div>
    </div>
  );
}
