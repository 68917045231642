import styles from "./Produk.module.css";
export default function Kmas() {
  return (
    <>
      <div className={`${styles.prodHeader} container-fluid`}>
        <p>Produk &gt; Program K-MAS</p>
        <h1>Program K-MAS</h1>
        <hr />
      </div>
      <div
        className={`${styles.prodDesc}  d-flex flex-column flex-lg-row justify-content-lg-evenly`}
      >
        <div className={`${styles.wrap1}`}>
          <h4>Apa yang dimaksud dengan Program K-MAS?</h4>
          <hr />
          <p>
            Program K-Mas adalah program kepemilikan logam mulia kepada
            masyarakat secara angsuran sebagai pilihan investasi, dan
            berdasarkan harga emas (logam mulia) dari tahun 2020 sampai 2022
            harga emas logam mulia memiliki kecenderungan naik maka dari itu
            logam mulia merupakan investasi emas yang paling populer & aman
            karena logam mulia dapat di jual dengan mudah dan cepat dan aman.
            <br />
            <br />
          </p>
          <h4>Keunggulan Program K-MAS</h4>
          <hr />
          <ul>
            <li>Logam mulia dapat dimiliki secara cicilan</li>
            <li>Jaminan emas 24 karat bersertifikat</li>
            <li>Beragam pilihan berat emas dari brand ANTAM</li>
            <li>Angsuran tetap meskipun harga emas naik</li>
            <li>Jangka waktu mulai dari 3 bulan hingga 36 bulan</li>
            <li>
              Proses mudah dengan layanan profesional <br />
              <br />
            </li>
          </ul>
        </div>
        <div className={`${styles.wrap2}`}>
          <h4>Apa syarat mengajukan Program K-MAS</h4>
          <hr />
          <p>Persyaratan yang umumnya diminta saat mengajukan program K-MAS</p>
          <ul>
            <li>WNI memiliki Legalitas KTP, KK dan data pendukung lainnya.</li>
            <li>
              Pegawai Usia minimum 21 tahun dan maksimum usia 57 tahun pada saat
              kredit lunas.
            </li>
            <li>
              Pensiunan berusia maksimal 70 tahun pada saat pembiayaan jatuh
              tempo.
            </li>
            <li>Professional dan wiraswasta berusia maksimal 60 tahun.</li>
            <li>NPWP (khusus pembiayaan diatas Rp50.000.000,-).</li>
            <li>Jangka waktu 3 bulan sampai maksimal 36 bulan.</li>
            <li>
              Minimal plafon kredit emas setara dengan emas seberat 3gram sampai
              dengan maksimal Rp150.000.000,-
            </li>
            <li>
              Bentuk emas yang dijadikan sebagai jaminan adalah emas batangan
              dari Antam/LM <br />
              <br />
            </li>
          </ul>
          <h4>Apa saja biaya dan ketentuan lainnya ?</h4>
          <hr />
          <ul>
            <li>Biaya Administrasi emas 3gram - 25gram Rp150.000,-</li>
            <li>
              Biaya Administrasi emas &gt;25gram = 1% dari plafond program K-MAS
            </li>
            <li>
              Pinalty jika pelunasan sebelum jatuh tempo kredit emas 5% dari
              sisa pokok pinjaman
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
