export default function AddGallery() {
  return (
    <>
      <div className="d-flex flex-column align-items-center">
        <h1>Add Gallery</h1>
        <form className="d-flex flex-column flex-md-row">
          <div className="form row gy-3">
            <div className="col-12 ">
              <label htmlFor="inputJudul" className="form-label">
                Judul
              </label>
              <input type="text" id="inputJudul" className="form-control" />
            </div>
            <div className="col-12 ">
              <label htmlFor="inputFoto" className="form-label">
                Foto
              </label>
              <input type="file" id="inputFoto" className="form-control" />
            </div>
            <div className="col-12 d-flex justify-content-center">
              <button>Submit</button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
