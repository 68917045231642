import styles from "../produk/Produk.module.css";

import lap20 from "../../assets/photos/Publikasi/Laporan-Keuangan-Publikasi-Desember-Tahun-2020_0011-791x1024.jpg";
import lap21 from "../../assets/photos/Publikasi/Laporan-Keuangan-Publikasi-Desember-2021-OK_001.jpg";
import lap22 from "../../assets/photos/Publikasi/A4-LKP-Desember-2022_001.png";

export default function Publikasi() {
  const laporan = [lap20, lap21, lap22];

  return (
    <>
      <div className={`${styles.prodHeader} container-fluid`}>
        <p>Beranda &gt; Laporan Publikasi Keuangan</p>
        <h1>Laporan Publikasi Keuangan</h1>
        <hr />
      </div>
      <div
        className={`${styles.prodDesc} d-flex flex-column justify-content-lg-evenly`}
      >
        {laporan.map((lap, idx) => {
          return <img className="w-100" src={lap} alt="laporan" />;
        })}
      </div>
    </>
  );
}
