import styles from "./Karir.module.css";

import age from "../../assets/profile.svg";
import edu from "../../assets/education.svg";

export default function Karir() {
  return (
    <>
      <div className={`${styles.karirHead}`}>
        <p>Beranda &gt; Karir</p>
        <h1>Karir</h1>
        <hr />
      </div>
      <div className={`${styles.karirBody}`}>
        <h2>Kembangkan Karir Bersama Kami!</h2>
        <hr />
        <div className="row g-4">
          <div
            className="col-12 col-md-4"
            onClick={() => (window.location = "/karir/1")}
          >
            <div className={`${styles.card}`}>
              <h4>Account Officer</h4>
              <div className="d-flex align-items-center">
                <img src={age} alt="age" />
                <p>20-30 Tahun</p>
              </div>
              <div className="d-flex align-items-center">
                <img src={edu} alt="" />
                <p>Min. D3</p>
              </div>
              <button>Read More &gt;</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
