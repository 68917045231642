import { useParams } from "react-router-dom";

import styles from "./Galeri.module.css";

import placeholder from "../../assets/Rectangle 54.png";

// HARDCODE UPDATE SOON
import img1 from "../../assets/photos/Galeri/Literasi dan Inklusi Keuangan Akses Keuangan Merata Masyarakat Sejahtera/IMG_20230728_085148.jpg";
import img2 from "../../assets/photos/Galeri/Literasi dan Inklusi Keuangan Akses Keuangan Merata Masyarakat Sejahtera/IMG_20230728_085915.jpg";
import img3 from "../../assets/photos/Galeri/Literasi dan Inklusi Keuangan Akses Keuangan Merata Masyarakat Sejahtera/IMG_20230728_092843.jpg";
import img4 from "../../assets/photos/Galeri/Literasi dan Inklusi Keuangan Akses Keuangan Merata Masyarakat Sejahtera/IMG_20230728_093808.jpg";
import img5 from "../../assets/photos/Galeri/Literasi dan Inklusi Keuangan Akses Keuangan Merata Masyarakat Sejahtera/IMG_20230728_093909.jpg";
import img6 from "../../assets/photos/Galeri/Literasi dan Inklusi Keuangan Akses Keuangan Merata Masyarakat Sejahtera/IMG_20230728_093954.jpg";
import img7 from "../../assets/photos/Galeri/Literasi dan Inklusi Keuangan Akses Keuangan Merata Masyarakat Sejahtera/IMG_20230728_111947.jpg";
import img8 from "../../assets/photos/Galeri/Literasi dan Inklusi Keuangan Akses Keuangan Merata Masyarakat Sejahtera/IMG_20230728_112419.jpg";
import img9 from "../../assets/photos/Galeri/Literasi dan Inklusi Keuangan Akses Keuangan Merata Masyarakat Sejahtera/IMG_20230728_113119.jpg";
import img10 from "../../assets/photos/Galeri/Literasi dan Inklusi Keuangan Akses Keuangan Merata Masyarakat Sejahtera/IMG_20230728_113122.jpg";

export default function GeleriDetail() {
  const id = useParams().id;

  const imgs = [img2, img3, img4, img5, img6, img7, img8, img9, img10];
  if (id === "1") {
    return (
      <>
        <div className={`${styles.galeriHead} container-fluid`}>
          <p>Beranda &gt; Galeri</p>
          <h1>
            Literasi dan Inklusi Keuangan Akses Keuangan Merata Masyarakat
            Sejahtera
          </h1>
          <hr />
        </div>
        <div className={`${styles.galeriBody}`}>
          <div className="row g-4">
            <div className="col-12 col-lg-6">
              <img src={img1} id={`${styles.thumbMain}`} alt="" />
            </div>
            <div className={`${styles.secondary} col-12 col-lg-6`}>
              <div className="row g-3">
                {imgs.map((image, idx) => {
                  return (
                    <div className="col-4" key={idx}>
                      <img src={image} alt="" />
                    </div>
                  );
                })}
              </div>
            </div>
            <div className={`${styles.third}`}></div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className={`${styles.galeriHead} container-fluid`}>
          <p>Beranda &gt; Galeri</p>
          <h1>Gathering Bali 2017</h1>
          <hr />
        </div>
        <div className={`${styles.galeriBody}`}>
          <div className="row g-4">
            <div className="col-12 col-lg-6">
              <img src={placeholder} id={`${styles.thumbMain}`} alt="" />
            </div>
            <div className={`${styles.secondary} col-12 col-lg-6`}>
              <div className="row g-3">
                <div className="col-4">
                  <img src={placeholder} alt="" />
                </div>
                <div className="col-4">
                  <img src={placeholder} alt="" />
                </div>
                <div className="col-4">
                  <img src={placeholder} alt="" />
                </div>
                <div className="col-4">
                  <img src={placeholder} alt="" />
                </div>
                <div className="col-4">
                  <img src={placeholder} alt="" />
                </div>
                <div className="col-4">
                  <img src={placeholder} alt="" />
                </div>
                <div className="col-4">
                  <img src={placeholder} alt="" />
                </div>
                <div className="col-4">
                  <img src={placeholder} alt="" />
                </div>
                <div className="col-4">
                  <img src={placeholder} alt="" />
                </div>
              </div>
            </div>
            <div className={`${styles.third}`}>
              <div className="row g-3">
                <div className="col-2">
                  <img src={placeholder} alt="" />
                </div>
                <div className="col-2">
                  <img src={placeholder} alt="" />
                </div>
                <div className="col-2">
                  <img src={placeholder} alt="" />
                </div>
                <div className="col-2">
                  <img src={placeholder} alt="" />
                </div>
                <div className="col-2">
                  <img src={placeholder} alt="" />
                </div>
                <div className="col-2">
                  <img src={placeholder} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
