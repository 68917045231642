import styles from "./Tentang.module.css";
import placeholder from "../../assets/placeholder.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import { useRef, useState } from "react";

// HARDCODE UPDATE SOON
import nas1 from "../../assets/photos/Logo Nasabah/EVMoto.jpg";
import nas2 from "../../assets/photos/Logo Nasabah/PT. Sepatu Mas Idaman.png";
import nas3 from "../../assets/photos/Logo Nasabah/PT Metagunna Innova (motocare).png";

export default function Testimoni() {
  const swiperRef = useRef();
  const [navView, setNavView] = useState(true);

  return (
    <>
      <div className={`${styles.tentangHeader} container-fluid`}>
        <p>Tentang &gt; Testimoni Nasabah</p>
        <h1>Testimoni Nasabah</h1>
        <hr />
      </div>
      <div className={`${styles.tentangDesc} ${styles.testimoni}`}>
        <Swiper
          className={`${styles.swiper}`}
          modules={[Navigation, Pagination, Autoplay]}
          navigation={{
            prevEl: `.${styles.swiperButtonPrev}`,
            nextEl: `.${styles.swiperButtonNext}`,
            clickable: true,
          }}
          pagination={{
            el: `.${styles.swiperPagination}`,
            clickable: true,
          }}
          loop={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          slidesPerView={1}
          centeredSlides={true}
          spaceBetween={20}
          onBeforeInit={(swiper) => {
            swiperRef.current = swiper;
          }}
          onInit={(swiper) => {
            if (swiper.slides.length <= 1) {
              setNavView(false);
            }
          }}
          ref={swiperRef}
        >
          <SwiperSlide>
            <div className={`${styles.cards} row`}>
              {/* <div className="col-12 col-md-6 p-3 card-wrapper">
                <div className={`${styles.card} d-flex flex-row`}>
                  <img src={nas1} alt="" />
                  <div>
                    <h4>PT. ECGO EvMoto</h4>
                    <hr />
                    <p>
                      Saya senang sekali bisa tau ada BPR AGS. Karena dibanding
                      yang lain, BPR AGS prosesnya cepat dan enggak dipersulit.
                      Pelayanannya juga bagus. Terimakasih BPR AGS
                    </p>
                  </div>
                </div>
              </div> */}
              <div className="col-12 col-md-6 p-3 card-wrapper">
                <div className={`${styles.card} d-flex flex-row`}>
                  <img src={nas2} alt="" />
                  <div>
                    <h4>PT Metaguna Innova (motocare)</h4>
                    <hr />
                    <p>
                      Sangat terbantu sekali dengan BPR AGS jika ada keperluan.
                      Prosesnya cepat dan mudah, bunganya pun rendah
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 p-3 card-wrapper">
                <div className={`${styles.card} d-flex flex-row`}>
                  <img src={nas3} alt="" />
                  <div>
                    <h4>PT. Sepatu Mas Idaman</h4>
                    <hr />
                    <p>Pelayanan baik, transaksi aman dan terpercaya.</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 p-3 card-wrapper">
                <div className={`${styles.card} d-flex flex-row`}>
                  <img src={placeholder} alt="" />
                  <div>
                    <h4>Hotel Alila</h4>
                    <hr />
                    <p>
                      Kami sudah lama menjadi nasabah BPR AGS, kredit yang
                      diberikan kepada kami sangat membantu usaha kami sampai
                      hari ini
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          {navView && (
            <div className="d-flex justify-content-center">
              <div
                className={`${styles.swiperButtonPrev}`}
                onClick={() => swiperRef.current?.slidePrev()}
              ></div>
              <div className={`${styles.swiperPagination}`}></div>
              <div
                className={`${styles.swiperButtonNext}`}
                onClick={() => swiperRef.current?.slideNext()}
              ></div>
            </div>
          )}
        </Swiper>
        {/* <h3>Berikan Testimoni untuk Kami</h3>
        <form className="d-flex flex-column flex-md-row">
          <div className="form-wrap-1 row gy-3 me-md-3">
            <div className="col-12 col-md-4">
              <label htmlFor="inputNama" className="form-label">
                Nama
              </label>
            </div>
            <div className="col-12 col-md-8">
              <input
                type="text"
                id="inputNama"
                className="form-control"
                placeholder="Tuliskan nama lengkap anda"
              />
            </div>
            <div className="col-12 col-md-4">
              <label htmlFor="inputEmail" className="form-label">
                Email
              </label>
            </div>
            <div className="col-12 col-md-8">
              <input
                type="text"
                id="inputEmail"
                className="form-control"
                placeholder="Tuliskan Email anda"
              />
            </div>
            <div className="col-12 col-md-4">
              <label htmlFor="inputProfesi" className="form-label">
                Profesi/Usaha
              </label>
            </div>
            <div className="col-12 col-md-8">
              <input
                type="text"
                id="inputProfesi"
                className="form-control"
                placeholder="Tuliskan Profesi atau jenis usaha anda"
              />
            </div>
          </div>
          <div className="form-wrap-2 row gy-3">
            <div className="col-12 col-md-4">
              <label htmlFor="inputNama" className="form-label">
                Testimonial
              </label>
            </div>
            <div className="col-12 col-md-8">
              <textarea
                className="form-control"
                placeholder="Tuliskan pengalaman anda setelah bergabung dengan BPR AGS"
              ></textarea>
            </div>
            <div className="col-12 col-md-4">
              <label htmlFor="inputPhoto" className="form-label">
                Photo
              </label>
            </div>
            <div className="col-12 col-md-8">
              <input id="inputPhoto" type="file" className="form-control" />
            </div>
            <div className="col-12 col-md-3">
              <button>Tambah</button>
            </div>
          </div>
        </form> */}
      </div>
    </>
  );
}
