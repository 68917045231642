import styles from "./Tentang.module.css";
import arrow from "../../assets/arrow.svg";
import link from "../../assets/link.svg";
import { useState } from "react";

// HARDCODE UPDATE SOON
import award2012 from "../../assets/photos/Penghargaan Infobank/2012.jpg";
import award2013 from "../../assets/photos/Penghargaan Infobank/2013.jpg";
import award2014 from "../../assets/photos/Penghargaan Infobank/2014.jpg";
import award2015 from "../../assets/photos/Penghargaan Infobank/2015.jpg";
import award2016 from "../../assets/photos/Penghargaan Infobank/2016.jpg";
import award2017 from "../../assets/photos/Penghargaan Infobank/2017.jpg";
import award2019 from "../../assets/photos/Penghargaan Infobank/2019.jpg";
import award2020 from "../../assets/photos/Penghargaan Infobank/2020.jpg";
import award2021 from "../../assets/photos/Penghargaan Infobank/2021.jpg";
import awardgold1 from "../../assets/photos/Penghargaan Infobank/Golden Awards 2012-2016.jpg";
import awardgold2 from "../../assets/photos/Penghargaan Infobank/Golden Awards 2013-2017.jpg";

export default function Penghargaan() {
  const [year, setYear] = useState();
  const [img, setImg] = useState();

  return (
    <>
      <div className={`${styles.tentangHeader} container-fluid`}>
        <p>Tentang &gt; Penghargaan</p>
        <h1>Penghargaan</h1>
        <hr />
      </div>
      <div
        className={`${styles.tentangDesc} d-flex flex-column flex-lg-row justify-content-lg-evenly`}
      >
        <div className={`row ${styles.penghargaanContainer}`}>
          <div className="col-8">
            <div className={`d-flex flex-column ${styles.penghargaan}`}>
              <div
                className={`d-flex justify-content-between ${styles.penghargaanToggler}`}
                onClick={() => {
                  if (year !== 2021) setYear(2021);
                  else setYear();
                }}
              >
                <h6>2021</h6>
                <img
                  src={arrow}
                  alt="arrow"
                  style={{ rotate: year === 2021 ? "0deg" : "-90deg" }}
                />
              </div>
              {year === 2021 && (
                <div className="d-flex flex-column">
                  <div
                    className={`${styles.penghargaanItem}`}
                    onClick={() => setImg(award2021)}
                  >
                    <h6>Infobank 13th BPR Awards</h6>
                    <div className="d-flex penghargaan-detail align-items-start">
                      <img src={link} alt="link" />
                      <p>
                        Penghargaan atas Kinerja Keuangan dengan Predikat
                        "SANGAT BAGUS" Selama Tahun 2021
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className={`d-flex flex-column ${styles.penghargaan}`}>
              <div
                className={`d-flex justify-content-between ${styles.penghargaanToggler}`}
                onClick={() => {
                  if (year !== 2020) setYear(2020);
                  else setYear();
                }}
              >
                <h6>2020</h6>
                <img
                  src={arrow}
                  alt="arrow"
                  style={{ rotate: year === 2020 ? "0deg" : "-90deg" }}
                />
              </div>
              {year === 2020 && (
                <div className="d-flex flex-column">
                  <div
                    className={`${styles.penghargaanItem}`}
                    onClick={() => setImg(award2020)}
                  >
                    <h6>Infobank award 2020</h6>
                    <div className="d-flex penghargaan-detail align-items-start">
                      <img src={link} alt="link" />
                      <p>Apresiasi Perbarindo Komisariat Depok</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className={`d-flex flex-column ${styles.penghargaan}`}>
              <div
                className={`d-flex justify-content-between ${styles.penghargaanToggler}`}
                onClick={() => {
                  if (year !== 2019) setYear(2019);
                  else setYear();
                }}
              >
                <h6>2019</h6>
                <img
                  src={arrow}
                  alt="arrow"
                  style={{ rotate: year === 2019 ? "0deg" : "-90deg" }}
                />
              </div>
              {year === 2019 && (
                <div className="d-flex flex-column">
                  <div
                    className={`${styles.penghargaanItem}`}
                    onClick={() => setImg(award2019)}
                  >
                    <h6>Infobank 11th BPR Awards</h6>
                    <div className="d-flex penghargaan-detail align-items-start">
                      <img src={link} alt="link" />
                      <p>
                        Penghargaan atas Kinerja Keuangan dengan Predikat
                        "SANGAT BAGUS" Selama Tahun 2019
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className={`d-flex flex-column ${styles.penghargaan}`}>
              <div
                className={`d-flex justify-content-between ${styles.penghargaanToggler}`}
                onClick={() => {
                  if (year !== 2017) setYear(2017);
                  else setYear();
                }}
              >
                <h6>2017</h6>
                <img
                  src={arrow}
                  alt="arrow"
                  style={{ rotate: year === 2017 ? "0deg" : "-90deg" }}
                />
              </div>
              {year === 2017 && (
                <div className="d-flex flex-column">
                  <div
                    className={`${styles.penghargaanItem}`}
                    onClick={() => setImg(award2017)}
                  >
                    <h6>Infobank 9th BPR Awards</h6>
                    <div className="d-flex penghargaan-detail align-items-start">
                      <img src={link} alt="link" />
                      <p>
                        Penghargaan atas Kinerja Keuangan dengan Predikat
                        "SANGAT BAGUS" Selama Tahun 2017
                      </p>
                    </div>
                  </div>
                  <div
                    className={`${styles.penghargaanItem}`}
                    onClick={() => setImg(awardgold2)}
                  >
                    <h6>Infobank 9th BPR Awards</h6>
                    <div className="d-flex penghargaan-detail align-items-start">
                      <img src={link} alt="link" />
                      <p>
                        Penghargaan atas Kinerja Keuangan dengan Predikat
                        "SANGAT BAGUS" Selama Tahun 2013-2017
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className={`d-flex flex-column ${styles.penghargaan}`}>
              <div
                className={`d-flex justify-content-between ${styles.penghargaanToggler}`}
                onClick={() => {
                  if (year !== 2016) setYear(2016);
                  else setYear();
                }}
              >
                <h6>2016</h6>
                <img
                  src={arrow}
                  alt="arrow"
                  style={{ rotate: year === 2016 ? "0deg" : "-90deg" }}
                />
              </div>
              {year === 2016 && (
                <div className="d-flex flex-column">
                  <div
                    className={`${styles.penghargaanItem}`}
                    onClick={() => setImg(award2016)}
                  >
                    <h6>Infobank 8th BPR Awards</h6>
                    <div className="d-flex penghargaan-detail align-items-start">
                      <img src={link} alt="link" />
                      <p>
                        Penghargaan atas Kinerja Keuangan dengan Predikat
                        "SANGAT BAGUS" Selama Tahun 2016
                      </p>
                    </div>
                  </div>
                  <div
                    className={`${styles.penghargaanItem}`}
                    onClick={() => setImg(awardgold1)}
                  >
                    <h6>Infobank 8th BPR Awards</h6>
                    <div className="d-flex penghargaan-detail align-items-start">
                      <img src={link} alt="link" />
                      <p>
                        Penghargaan atas Kinerja Keuangan dengan Predikat
                        "SANGAT BAGUS" Selama Tahun 2012-2016
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className={`d-flex flex-column ${styles.penghargaan}`}>
              <div
                className={`d-flex justify-content-between ${styles.penghargaanToggler}`}
                onClick={() => {
                  if (year !== 2015) setYear(2015);
                  else setYear();
                }}
              >
                <h6>2015</h6>
                <img
                  src={arrow}
                  alt="arrow"
                  style={{ rotate: year === 2015 ? "0deg" : "-90deg" }}
                />
              </div>
              {year === 2015 && (
                <div className="d-flex flex-column">
                  <div
                    className={`${styles.penghargaanItem}`}
                    onClick={() => setImg(award2015)}
                  >
                    <h6>Infobank 7th BPR Awards</h6>
                    <div className="d-flex penghargaan-detail align-items-start">
                      <img src={link} alt="link" />
                      <p>
                        Penghargaan atas Kinerja Keuangan dengan Predikat
                        "SANGAT BAGUS" Selama Tahun 2015
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className={`d-flex flex-column ${styles.penghargaan}`}>
              <div
                className={`d-flex justify-content-between ${styles.penghargaanToggler}`}
                onClick={() => {
                  if (year !== 2014) setYear(2014);
                  else setYear();
                }}
              >
                <h6>2014</h6>
                <img
                  src={arrow}
                  alt="arrow"
                  style={{ rotate: year === 2014 ? "0deg" : "-90deg" }}
                />
              </div>
              {year === 2014 && (
                <div className="d-flex flex-column">
                  <div
                    className={`${styles.penghargaanItem}`}
                    onClick={() => setImg(award2014)}
                  >
                    <h6>Infobank 6th BPR Awards</h6>
                    <div className="d-flex penghargaan-detail align-items-start">
                      <img src={link} alt="link" />
                      <p>
                        Penghargaan atas Kinerja Keuangan dengan Predikat
                        "SANGAT BAGUS" Selama Tahun 2014
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className={`d-flex flex-column ${styles.penghargaan}`}>
              <div
                className={`d-flex justify-content-between ${styles.penghargaanToggler}`}
                onClick={() => {
                  if (year !== 2013) setYear(2013);
                  else setYear();
                }}
              >
                <h6>2013</h6>
                <img
                  src={arrow}
                  alt="arrow"
                  style={{ rotate: year === 2013 ? "0deg" : "-90deg" }}
                />
              </div>
              {year === 2013 && (
                <div className="d-flex flex-column">
                  <div
                    className={`${styles.penghargaanItem}`}
                    onClick={() => setImg(award2013)}
                  >
                    <h6>Infobank 5th BPR Awards</h6>
                    <div className="d-flex penghargaan-detail align-items-start">
                      <img src={link} alt="link" />
                      <p>
                        Penghargaan atas Kinerja Keuangan dengan Predikat
                        "SANGAT BAGUS" Selama Tahun 2013
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className={`d-flex flex-column ${styles.penghargaan}`}>
              <div
                className={`d-flex justify-content-between ${styles.penghargaanToggler}`}
                onClick={() => {
                  if (year !== 2012) setYear(2012);
                  else setYear();
                }}
              >
                <h6>2012</h6>
                <img
                  src={arrow}
                  alt="arrow"
                  style={{ rotate: year === 2012 ? "0deg" : "-90deg" }}
                />
              </div>
              {year === 2012 && (
                <div className="d-flex flex-column">
                  <div
                    className={`${styles.penghargaanItem}`}
                    onClick={() => setImg(award2012)}
                  >
                    <h6>Infobank 4th BPR Awards</h6>
                    <div className="d-flex penghargaan-detail align-items-start">
                      <img src={link} alt="link" />
                      <p>
                        Penghargaan atas Kinerja Keuangan dengan Predikat
                        "SANGAT BAGUS" Selama Tahun 2012
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="col-4">
            {img !== undefined && (
              <img src={img} alt="penghargaan" id={styles.penghargaanImg} />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
