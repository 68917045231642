import styles from "./Tentang.module.css";

export default function Sejarah() {
  return (
    <>
      <div className={`${styles.tentangHeader} container-fluid`}>
        <p>Tentang &gt; Sejarah PT. BPR Arthaguna Sejahtera</p>
        <h1>Sejarah PT. BPR Arthaguna Sejahtera</h1>
        <hr />
      </div>
      <div
        className={`${styles.tentangDesc} d-flex flex-column flex-lg-row justify-content-lg-evenly`}
      >
        <div className={`${styles.wrap1}`}>
          <p>
            Keberadaan BPR Arthaguna Sejahtera yang berdiri sejak tahun 1994 di
            Depok yang berasal dari PT. Bina Artha Sawangan ( selanjutnya Bina
            Sawangan Artha ) yang didirikan pada tanggal 7 Januari 1994
            merupakan salah satu Bank Perkreditan Rakyat Swasta Nasional.
            <br />
            <br />
            Pada tahun 2003 PT. BPR Bina Sawangan Artha termasuk salah satu BPR
            dalam pengawasan khusus Bank Indonesia atau dengan kata lain BPR
            dalam proses likuidasi. Kemudian pada tanggal 10 Oktober 2003,
            dengan izin dari Bank Indonesia dilakukan akuisisi oleh pemegang
            saham baru.
            <br />
            <br />
            Pertanggal 13 November 2003, akuisisi PT. BPR Bina Sawangan Artha
            telah disetujui dan dikeluarkan dari status Pengawasan Khusus Bank
            Indonesia, dengan tingkat kesehatan tergolong kurang sehat. Dan
            mulai tanggal tersebut BPR telah diizinkan untuk menyalurkan kredit
            ke masyarakat. Dan proses selanjutnya terjadi perubahan management,
            perubahan nama menjadi PT. BPR Arthaguna Sejahtera.
            <br />
            <br />
            Meningkatkan kesejahteraan keluarga pra-sejahtera merupakan Visi
            utama BPR Arthaguna Sejahtera dalam menjalankan usahanya. selain
            menyediakan fasilitas kredit modal kerja dan Investasi kepada para
            pengusaha Mikro dan pekerja / karyawan perusahaan, penyaluran Kredit
            Mikro kepada kelompok – kelompok usaha kecil dan ibu-ibu rumah
            tangga juga merupakan prioritas BPR Arthaguna Sejahtera
            <br />
            <br />
            Dalam rangka penyaluran kredit mikro, BPR Arthaguna Sejahtera
            mengutamakan peningkatan kesejahteraan masyarakat kecil, tercermin
            dalam produk-produk lending misalnya
            <ul>
              <li>
                Pinjaman Sejahtera – Pinjaman Kelompok Ibu-Ibu ( Metode Grammen
                )
              </li>
              <li>
                Pinjaman Multiguna untuk Karyawan perusahaan atau perorangan
              </li>
              <li>Pinjaman Multiguna untuk Modal kerja Pinjaman Kendaraan</li>
            </ul>
          </p>
        </div>
        <div className={`${styles.wrap2}`}>
          <p>
            Pada awal penyaluran kredit, BPR Artahguna Sejahtera memfokuskan
            penyaluran kepada Pinjaman Sejahtera dalam bentuk Kelompok Grammen,
            lalu selanjutnya ke Pinjaman Multiguna perorangan dengan jaminan dan
            Multiguna perusahaan ( Karyawan – Karyawan Perusahaan ). Dengan
            didukung oleh kurang lebih 50 orang karyawan , BPR Arthaguna
            Sejahtera akan senantiasa tumbuh dan berkembang tanpa mengabaikan
            kualitas pelayanan kepada nasabah melalui peningkatan kualitas
            Sumber Daya Manusia.
            <br />
            <br />
            Sejalan dengan perkembangannya BPR Arthaguna Sejahtera dalam hal
            pelayanannya terhadap nasabah sebagai fungsi Intermediasi bagi
            masyarakat sekitar pada akhir tahun 2012 kami telah mengajukan ijin
            prinsip kepada Bank Indonesia untuk membuka satu Kantor Cabang yang
            berlokasi di wilayah Kabupaten Bekasi. Sehingga ditargetkan tahun
            mendatang kami memiliki 2 kantor Cabang masing – masing di wilayah
            Tangerang dan Kabupaten Bekasi.
            <br />
            <br />
            Total Asset bank mengalami peningkatan yang cukup signifikan , hal
            ini sebagian besar terutama disebabkan oleh peningkatan jumlah
            Kredit yang diberikan pada tahun 2012 yang merupakan dampak positif
            dari pembukaan Cabang di wilayah Tangerang , perlakuan frezze
            linkage program Bank Umum berdampak kepada Kesulitan BPR untuk
            memperoleh sumber dana untuk kegiatan landing, namun Dana Pihak
            ketiga dalam bentuk Simpanan baik Tabungan dan Deposito mengalami
            peningkatan bila dibandingkan dengan tahun sebelumya sehingga secara
            otomatis berpengaruh cukup signifikan terhadap Total Asset pada
            tahun 2012.
          </p>
        </div>
      </div>
    </>
  );
}
