import { useState } from "react";

import placeholder from "../../assets/placeholder.png";
import clock from "../../assets/clock.svg";
import message from "../../assets/comment.svg";

import styles from "./Berita.module.css";

// HARDCODE UPDATE SOON
import ber1 from "../../assets/photos/Berita/berita1.jpg";
import ber2 from "../../assets/photos/Berita/berita2.jpeg";
import ber3 from "../../assets/photos/Berita/berita3.jpeg";
import ber4 from "../../assets/photos/Berita/berita4.jpg";

export default function Berita() {
  const [seeMore, setSeeMore] = useState(false);

  const beritaList = [
    {
      title:
        "LPS Bakal Luncurkan Sistem IT untuk Awasi BPR, Anggarannya Capai Rp 200 M",
      date: "25 Oktober 2023",
      category: "Uncategorized",
      img: ber1,
    },
    {
      title: "Gaji Rata-rata Lulusan SD, SMP, SMA, dan S1, Berapa Bedanya?",
      date: "27 Oktober 2023",
      category: "Uncategorized",
      img: ber2,
    },
    {
      title: "BI Rate Naik, Bagaimana Dampaknya pada Investasi Masyarakat?",
      date: "27 Oktober 2023",
      category: "Uncategorized",
      img: ber3,
    },
    {
      title:
        "Nilai Tukar Rupiah Melemah di Agustus 2023, BI Ungkap Penyebabnya",
      date: "10 September 2023",
      category: "Uncategorized",
      img: ber4,
    },
  ];

  return (
    <>
      <div className={`${styles.highlight} row`}>
        {beritaList.map((berita, idx) => {
          if (idx === 0) {
            return (
              <div
                className={`${styles.main} col-12 col-md-8 ps-0 pe-3`}
                key={idx}
                onClick={() => (window.location = `/berita/${idx + 1}`)}
              >
                <img className={`${styles.thumb}`} src={berita.img} alt="" />
                <h1>{berita.title}</h1>
                <div className={`d-flex align-items-center m-0`}>
                  <div className={`${styles.category}`}>
                    <p>{berita.category}</p>
                  </div>
                  <div className={`${styles.time} d-flex align-items-center`}>
                    <img width="17" height="17" src={clock} alt="" />
                    <p>{berita.date}</p>
                  </div>
                  <div
                    className={`${styles.comment} d-flex align-items-center`}
                  >
                    <img width="17" height="17" src={message} alt="" />
                    <p>0</p>
                  </div>
                </div>
              </div>
            );
          }
        })}

        <div
          className={`${styles.secondary} col-12 col-md-4 my-3 my-md-0 pe-0 ps-3`}
        >
          <div className="row gy-3 pe-3">
            {beritaList.map((berita, idx) => {
              if (idx !== 0) {
                return (
                  <div
                    className="col-12 col-sm-4 col-md-12 d-flex align-items-center p-0 "
                    key={idx}
                    onClick={() => (window.location = `/berita/${idx + 1}`)}
                  >
                    <img
                      className={`${styles.thumb}`}
                      src={berita.img}
                      alt=""
                    />
                    <div className="d-flex flex-column">
                      <div className={`${styles.category}`}>
                        <p>{berita.category}</p>
                      </div>
                      <h5>{berita.title}</h5>
                      <div className="d-flex flex-column flex-md-row details">
                        <div
                          className={`${styles.time} d-flex align-items-center`}
                        >
                          <img width="18" height="18" src={clock} alt="" />
                          <p>{berita.date}</p>
                        </div>
                        <div
                          className={`${styles.comment} d-flex align-items-center`}
                        >
                          <img width="18" height="18" src={message} alt="" />
                          <p>0</p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </div>
      </div>
      <div className={`${styles.others}`}>
        <div className={`${styles.section}`}>
          <h1>Berita Lainnya</h1>
          <hr />
        </div>
        <div className="row g-4">
          {beritaList.map((berita, idx) => {
            return (
              <div
                className="col-12 col-sm-6 col-md-3"
                key={idx}
                onClick={() => (window.location = `/berita/${idx + 1}`)}
              >
                <div className={`${styles.card}`}>
                  <img src={berita.img} alt="" />
                  <div className={`${styles.info}`}>
                    <h6>{berita.title}</h6>
                    <p className={`${styles.time}`}>{berita.date}</p>
                    <button>Read More &gt;</button>
                  </div>
                </div>
              </div>
            );
          })}

          {seeMore ? (
            <>
              <div className="col-12 col-sm-6 col-md-3">
                <div className={`${styles.card}`}>
                  <img src={placeholder} alt="" />
                  <div className={`${styles.info}`}>
                    <h6>
                      [POPULER JABODETABEK] Penumpang KRL Dilarang Pakai Buff
                      dan Masker Scuba
                    </h6>
                    <p className={`${styles.time}`}>17 September 2020</p>
                    <button>Read More &gt;</button>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3">
                <div className={`${styles.card}`}>
                  <img src={placeholder} alt="" />
                  <div className={`${styles.info}`}>
                    <h6>
                      [POPULER JABODETABEK] Penumpang KRL Dilarang Pakai Buff
                      dan Masker Scuba
                    </h6>
                    <p className={`${styles.time}`}>17 September 2020</p>
                    <button>Read More &gt;</button>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3">
                <div className={`${styles.card}`}>
                  <img src={placeholder} alt="" />
                  <div className={`${styles.info}`}>
                    <h6>
                      [POPULER JABODETABEK] Penumpang KRL Dilarang Pakai Buff
                      dan Masker Scuba
                    </h6>
                    <p className={`${styles.time}`}>17 September 2020</p>
                    <button>Read More &gt;</button>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <button
                  className={`${styles.see} col-2 float-end`}
                  onClick={() => setSeeMore(false)}
                >
                  <i>See less &lt;</i>
                </button>
              </div>
            </>
          ) : (
            <div className="col-12">
              <button
                className={`${styles.see} col-2 float-end`}
                onClick={() => setSeeMore(true)}
              >
                <i>See more &gt;</i>
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
