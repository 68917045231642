import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Controller, Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import styles from "./Home.module.css";

// HARDCODE UPDATE SOON
import slide1 from "../assets/photos/Slideshow Homepage/1. Semarak 20 Tahun BPRAGS.png";
import slide2 from "../assets/photos/Slideshow Homepage/2. Kredit Multiguna.png";
import slide3 from "../assets/photos/Slideshow Homepage/3. K-MAS.png";
import slide4 from "../assets/photos/Slideshow Homepage/4. Tabungan Setara.png";

import nas1 from "../assets/photos/Logo Nasabah/EVMoto.jpg";
import nas2 from "../assets/photos/Logo Nasabah/PT. Sepatu Mas Idaman.png";
import nas3 from "../assets/photos/Logo Nasabah/PT Metagunna Innova (motocare).png";

import ber1 from "../assets/photos/Berita/berita1.jpg";
import ber2 from "../assets/photos/Berita/berita2.jpeg";
import ber3 from "../assets/photos/Berita/berita3.jpeg";
import ber4 from "../assets/photos/Berita/berita4.jpg";

export default function Home() {
  const [controlledSwiper, setControlledSwiper] = useState(null);
  return (
    <>
      <div
        className={`${styles.carousel} d-flex flex-column flex-sm-row justify-content-center align-items-center`}
      >
        <Swiper
          className={`${styles.swiperPhoto} ${styles.swiper}`}
          modules={[Controller, Navigation, Pagination, Autoplay]}
          controller={{ control: controlledSwiper }}
          navigation={{ clickable: true }}
          pagination={{ clickable: true }}
          loop={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
        >
          <SwiperSlide>
            <img src={slide1} height="100%" alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={slide2} height="100%" alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={slide3} height="100%" alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={slide4} height="100%" alt="" />
          </SwiperSlide>
        </Swiper>
        <Swiper
          className={`${styles.swiperDesc}`}
          modules={[Controller]}
          onSwiper={setControlledSwiper}
          allowSlideNext={false}
          allowSlidePrev={false}
          noSwiping={true}
          loop={true}
        >
          <SwiperSlide>
            <h1>Siapa Kami?</h1>
            <hr />
            <p>
              PT. BPR Arthaguna Sejahtera merupakan Bank Perkreditan Rakyat yang
              berperan dan turut serta untuk menumbuhkan perekonomian Indonesia
              khususnya di wilayah Jabodetabek. Saat ini BPR Arthaguna Sejahtera
              memiliki satu kantor pusat yang terletak di Depok dan dua kantor
              cabang yang terletak di Tangerang dan Cikarang.
            </p>
          </SwiperSlide>
          <SwiperSlide>
            <h1>Siapa Kami?</h1>
            <hr />
            <p>
              PT. BPR Arthaguna Sejahtera merupakan Bank Perkreditan Rakyat yang
              berperan dan turut serta untuk menumbuhkan perekonomian Indonesia
              khususnya di wilayah Jabodetabek. Saat ini BPR Arthaguna Sejahtera
              memiliki satu kantor pusat yang terletak di Depok dan dua kantor
              cabang yang terletak di Tangerang dan Cikarang.
            </p>
          </SwiperSlide>
          <SwiperSlide>
            <h1>Siapa Kami?</h1>
            <hr />
            <p>
              PT. BPR Arthaguna Sejahtera merupakan Bank Perkreditan Rakyat yang
              berperan dan turut serta untuk menumbuhkan perekonomian Indonesia
              khususnya di wilayah Jabodetabek. Saat ini BPR Arthaguna Sejahtera
              memiliki satu kantor pusat yang terletak di Depok dan dua kantor
              cabang yang terletak di Tangerang dan Cikarang.
            </p>
          </SwiperSlide>
          <SwiperSlide>
            <h1>Siapa Kami?</h1>
            <hr />
            <p>
              PT. BPR Arthaguna Sejahtera merupakan Bank Perkreditan Rakyat yang
              berperan dan turut serta untuk menumbuhkan perekonomian Indonesia
              khususnya di wilayah Jabodetabek. Saat ini BPR Arthaguna Sejahtera
              memiliki satu kantor pusat yang terletak di Depok dan dua kantor
              cabang yang terletak di Tangerang dan Cikarang.
            </p>
          </SwiperSlide>
        </Swiper>
      </div>
      <div
        className={`${styles.testimony} d-flex flex-column align-items-center mt-3`}
      >
        <h1>Nasabah Kami</h1>
        <div className="d-flex flex-wrap justify-content-evenly align-items-center">
          {/* <div className={`${styles.cards}`}>
            <img src={nas1} alt="" />
            <h4>PT. ECGO EvMoto</h4>
            <hr />
            <p>
              Saya senang sekali bisa tau ada BPR AGS. Karena dibanding yang
              lain, BPR AGS prosesnya cepat dan enggak dipersulit. Pelayanannya
              juga bagus. Terimakasih BPR AGS
            </p>
          </div> */}
          <div className={`${styles.cards}`}>
            <img src={nas2} alt="" />
            <h4>PT. Sepatu Mas Idaman</h4>
            <hr />
            <p>
              Saya senang sekali bisa tau ada BPR AGS. Karena dibanding yang
              lain, BPR AGS prosesnya cepat dan enggak dipersulit. Pelayanannya
              juga bagus. Terimakasih BPR AGS
            </p>
          </div>
          <div className={`${styles.cards}`}>
            <img src={nas3} alt="" />
            <h4>PT. Metaguna Innova (motocare)</h4>
            <hr />
            <p>
              Sangat terbantu sekali dengan BPR AGS jika ada keperluan.
              Prosesnya cepat dan mudah, bunganya pun rendah.
            </p>
          </div>
        </div>
      </div>
      <div
        className={`${styles.news} d-flex flex-column align-items-center mt-3`}
      >
        <h1>Berita Terbaru</h1>
        <div className="d-flex flex-wrap justify-content-evenly align-items-center">
          <div
            className={`${styles.cards}`}
            onClick={() => (window.location = "/berita/1")}
          >
            <img src={ber1} alt="" />
            <div className={`${styles.info}`}>
              <p className={`${styles.title}`}>
                LPS Bakal Luncurkan Sistem IT untuk Awasi BPR, Anggarannya Capai
                Rp 200 M
              </p>
              <p>25 Oktober 2023</p>
              <button>Read More &gt;</button>
            </div>
          </div>
          <div
            className={`${styles.cards}`}
            onClick={() => (window.location = "/berita/2")}
          >
            <img src={ber2} alt="" />
            <div className={`${styles.info}`}>
              <p className={`${styles.title}`}>
                Gaji Rata-rata Lulusan SD, SMP, SMA, dan S1, Berapa Bedanya?
              </p>
              <p>27 Oktober 2023</p>
              <button>Read More &gt;</button>
            </div>
          </div>
          <div
            className={`${styles.cards}`}
            onClick={() => (window.location = "/berita/3")}
          >
            <img src={ber3} alt="" />
            <div className={`${styles.info}`}>
              <p className={`${styles.title}`}>
                BI Rate Naik, Bagaimana Dampaknya pada Investasi Masyarakat?
              </p>
              <p>27 Oktober 2023</p>
              <button>Read More &gt;</button>
            </div>
          </div>
          <div
            className={`${styles.cards}`}
            onClick={() => (window.location = "/berita/4")}
          >
            <img src={ber4} alt="" />
            <div className={`${styles.info}`}>
              <p className={`${styles.title}`}>
                Nilai Tukar Rupiah Melemah di Agustus 2023, BI Ungkap
                Penyebabnya
              </p>
              <p>10 September 2023</p>
              <button>Read More &gt;</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
