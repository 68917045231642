export default function Panel() {
  return (
    <>
      <div className="d-flex justify-content-center align-items-center">
        <div className="row">
          <div className="col-6">
            <button className="d-flex">Berita</button>
          </div>
          <div className="col-6">
            <button>Gallery</button>
          </div>
          <div className="col-6">
            <button>Penghargaan</button>
          </div>
          <div className="col-6">
            <button>Slideshow</button>
          </div>
        </div>
      </div>
    </>
  );
}
