import { NavLink } from "react-router-dom";
import "./Header.css";
import logo from "../assets/LogoBPR.jpg";

export default function Header() {
  return (
    <div className="header container-fluid p-0">
      <div className="contact d-flex justify-content-between p-2">
        <div className="operational d-flex flex-column flex-sm-row align-items-start">
          <div className="phone d-flex mx-0 mx-sm-1">
            <img
              src="https://img.icons8.com/ios-glyphs/30/phone--v1.png"
              alt="phone"
            />
            <p>(021) 77880066</p>
          </div>
          <div className="clock d-flex mx-0 mx-sm-1">
            <img
              src="https://img.icons8.com/ios-glyphs/30/000000/clock--v1.png"
              alt="clock--v1"
            />
            <p>Senin - Jum'at: 08.00 - 17.00</p>
          </div>
          <div className="email d-flex mx-0 mx-sm-1">
            <img
              src="https://img.icons8.com/ios-glyphs/30/new-post.png"
              alt="new-post"
            />
            <p>cs@bprags.co.id</p>
          </div>
        </div>
        <div className="socials d-flex">
          <a href="https://www.facebook.com/bpragsofficial?ref=embed_page">
            <img
              height="50%"
              src="https://img.icons8.com/ios-glyphs/30/facebook.png"
              alt="facebook"
            />
          </a>
          <a href="https://www.instagram.com/bprarthagunasejahtera/">
            <img
              height="50%"
              src="https://img.icons8.com/ios/30/instagram-new--v1.png"
              alt="instagram-new--v1"
            />
          </a>
        </div>
      </div>
      <nav className="navbar navbar-expand-xxl shadow py-0 px-sm-4">
        <div className="container-fluid">
          <img src={logo} alt="Logo BPR" />
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse justify-content-end"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav m-0">
              <li className="nav-item">
                <NavLink to="/">Beranda</NavLink>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href=""
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Produk
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <NavLink to="/produk/tabungan" className="dropdown-item">
                      Tabungan
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/produk/k-mas" className="dropdown-item">
                      K-MAS
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/produk/deposito" className="dropdown-item">
                      Deposito
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <NavLink to="/simulasi">Simulasi</NavLink>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href=""
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Tentang
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <NavLink to="/tentang/pengertian" className="dropdown-item">
                      Pengertian
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/tentang/sejarah" className="dropdown-item">
                      Sejarah
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/tentang/visi-misi" className="dropdown-item">
                      Visi Misi
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/tentang/penghargaan"
                      className="dropdown-item"
                    >
                      Penghargaan
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/tentang/testimoni" className="dropdown-item">
                      Testimoni Nasabah
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <NavLink to="/berita">Berita</NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/galeri">Galeri</NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/kontak">Kontak</NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/karir">Karir</NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="https://docs.google.com/forms/d/e/1FAIpQLScyDVtoBRrE-Fyxf0TNrXauvbg4HfSFDaECaUS6otf5FayD9w/viewform">
                  Pengajuan Pinjaman
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="https://drive.google.com/file/d/19Xdo20BIwA9JzVyGmnB71sTsoTBwQw4p/view?usp=drive_link">
                  AGS Mobile
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="https://play.google.com/store/apps/details?id=com.dafturn.bprecash&pli=1">
                  BPR E-Cash
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}
