import axios from "axios";
import { useEffect, useState } from "react";

export default function Login() {
  const [login, setLogin] = useState({
    username: "",
    password: "",
  });

  const onLoginChangeHandler = (e) => {
    setLogin({
      ...login,
      [e.target.name]: e.target.value,
    });
  };

  // useEffect(() => {
  //   fetchUser();
  // }, []);

  // const fetchUser = async () => {
  //   try {
  //     const result = await axios("http://localhost:3001/users");
  //     console.log(result);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  const onLoginHandler = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("http://localhost:3001/login", login);
      console.log(response);
      if (response.data.length > 0) {
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-center">
        <form className="d-flex flex-column flex-md-row">
          <div className="form row gy-3">
            <div className="col-12 ">
              <label htmlFor="inputUsername" className="form-label">
                Username
              </label>
              <input
                type="text"
                id="inputUsername"
                className="form-control"
                name="username"
                onChange={(e) => onLoginChangeHandler(e)}
              />
            </div>
            <div className="col-12 ">
              <label htmlFor="inputPassword" className="form-label">
                Password
              </label>
              <input
                type="text"
                id="inputPassword"
                className="form-control"
                onChange={(e) => onLoginChangeHandler(e)}
                name="password"
              />
            </div>
            <div className="col-12 d-flex justify-content-center">
              <button type="submit" onClick={(e) => onLoginHandler(e)}>
                Login
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
