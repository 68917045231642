import { useEffect, useRef, useState } from "react";
import "./Simulasi.css";
export default function Simulasi() {
  // const gajiRef = useRef();
  // const DBRRef = useRef();
  const jangkaRef = useRef();
  const kreditRef = useRef();
  // const [ageDesc, setAgeDesc] = useState("Mohon masukkan usia anda");
  const [simul, setSimul] = useState("");
  const [error, setError] = useState("");
  const [bunga, setBunga] = useState(30);

  // const ageHandler = (event) => {
  //   const today = new Date();
  //   const input = new Date(event.target.value);
  //   const years = Math.floor((today.getTime() - input.getTime()) / 31556736000);
  //   if (years >= 17 && years <= 100) {
  //     setAgeDesc(`Usia anda saat ini ${years} tahun`);
  //   } else {
  //     setAgeDesc(`Mohon masukkan usia yang valid`);
  //   }
  // };

  const rupiah = (number) =>
    new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
    }).format(number);

  // const onSimulHandler = (e) => {
  //   e.preventDefault();
  //   const DBR = DBRRef.current.value;
  //   const jangka = jangkaRef.current.value;
  //   const kredit = kreditRef.current.value;
  //   const bunga = bungaRef.current.value;

  //   const cicilanPokok = kredit / (jangka * 12);
  //   const bungaCalc = (kredit * bunga * 0.01) / 12;
  //   const cicilanJumlah = cicilanPokok + bungaCalc;

  //   setSimul({
  //     prov: rupiah(kredit * 0.01),
  //     admin: rupiah(kredit * 0.03),
  //     asuransi: rupiah(0),
  //     jumlah: rupiah(kredit * 0.96),
  //     angsuran: rupiah(cicilanJumlah),
  //   });
  // };

  // return (
  //   <>
  //     <div className="prod-header container-fluid">
  //       <p>Beranda &gt; Simulasi Kredit Pensiun</p>
  //       <h1>Simulasi Kredit Pensiun</h1>
  //       <hr />
  //     </div>
  //     <div className="prod-desc">
  //       <h3>Yuk coba simulasikan kredit pensiun kamu!</h3>
  //       <div className="wrapper d-flex flex-column flex-lg-row">
  //         <div className="wrap-1">
  //           <form className="d-flex flex-column flex-md-row">
  //             <div className="form-wrap-1 row gy-2 me-md-3 overflow-hidden">
  //               <div className="col-12 col-md-4">
  //                 <label htmlFor="inputTanggal" className="form-label">
  //                   Tanggal Lahir
  //                 </label>
  //               </div>
  //               <div className="col-12 col-md-8">
  //                 <input
  //                   type="date"
  //                   id="inputTanggal"
  //                   className="form-control"
  //                   onChange={(e) => ageHandler(e)}
  //                 />
  //                 <div className="form-text">{ageDesc}</div>
  //               </div>
  //               <div className="col-12 col-md-4">
  //                 <label htmlFor="inputGaji" className="form-label">
  //                   Gaji / Bulan
  //                 </label>
  //               </div>
  //               <div className="col-12 col-md-8">
  //                 <input
  //                   type="number"
  //                   id="inputGaji"
  //                   className="form-control"
  //                   ref={gajiRef}
  //                 />
  //               </div>
  //               <div className="col-12 col-md-4">
  //                 <label htmlFor="inputDBR" className="form-label">
  //                   DBR
  //                 </label>
  //               </div>
  //               <div className="col-12 col-md-8">
  //                 <select
  //                   id="inputDBR"
  //                   className="form-select"
  //                   defaultValue={0}
  //                   ref={DBRRef}
  //                 >
  //                   <option value={0.7}>70%</option>
  //                   <option value={0.9}>90%</option>
  //                 </select>
  //               </div>
  //               <div className="col-12 col-md-4">
  //                 <label htmlFor="inputJangka" className="form-label">
  //                   Jangka waktu (tahun)
  //                 </label>
  //               </div>
  //               <div className="col-12 col-md-8">
  //                 <select
  //                   id="inputJangka"
  //                   className="form-select"
  //                   defaultValue={1}
  //                   ref={jangkaRef}
  //                 >
  //                   <option value={1}>1</option>
  //                   <option value={2}>2</option>
  //                   <option value={3}>3</option>
  //                   <option value={4}>4</option>
  //                   <option value={5}>5</option>
  //                 </select>
  //               </div>
  //             </div>
  //             <div className="form-wrap-2 row gy-2">
  //               <div className="col-12 col-md-4">
  //                 <label htmlFor="inputKredit" className="form-label">
  //                   Jumlah Kredit
  //                 </label>
  //               </div>
  //               <div className="col-12 col-md-8">
  //                 <input
  //                   type="number"
  //                   id="inputKredit"
  //                   className="form-control"
  //                   defaultValue={0}
  //                   placeholder={0}
  //                   ref={kreditRef}
  //                 />
  //               </div>
  //               <div className="col-12 col-md-4">
  //                 <label htmlFor="inputBunga" className="form-label">
  //                   Bunga (%)
  //                 </label>
  //               </div>
  //               <div className="col-12 col-md-8">
  //                 <input
  //                   type="number"
  //                   id="inputBunga"
  //                   className="form-control"
  //                   defaultValue={15.0}
  //                   ref={bungaRef}
  //                   disabled
  //                 />
  //               </div>
  //               <div className="col-md-4"></div>
  //               <div className="col-12 col-md-8">
  //                 <button onClick={(e) => onSimulHandler(e)}>Hitung</button>
  //               </div>
  //               <div className="col-md-12"></div>
  //               <div className="col-md-12"></div>
  //             </div>
  //           </form>
  //         </div>
  //         {simul.jumlah !== "" && (
  //           <div className="wrap-2 border p-4">
  //             <p>Potongan biaya provinsi 1% : {simul.prov}</p>
  //             <p>Potongan biaya admin 3% : {simul.admin}</p>
  //             <p>Potongan biaya asuransi : {simul.asuransi}</p>
  //             <p>Jumlah diterima :</p>
  //             <h5>{simul.jumlah}</h5>
  //             <p>Angsuran per Bulan :</p>
  //             <h5>{simul.angsuran}</h5>
  //           </div>
  //         )}
  //       </div>
  //     </div>
  //   </>
  // );

  useEffect(() => {
    console.log(bunga);
  }, [bunga]);

  const kreditHandler = (e) => {
    const kredit = e.target.value;
    if (kredit >= 51000000) {
      setBunga(24);
      setError("");
    } else if (kredit >= 11000000) {
      setBunga(27);
      setError("");
    } else if (kredit >= 1000000) {
      setBunga(30);
      setError("");
    } else {
      setError("Jumlah Kredit Minimum Sebesar Rp 1.000.000,00");
    }
  };

  const onSimulHandler = (e) => {
    e.preventDefault();
    const jangka = jangkaRef.current.value;
    const kredit = kreditRef.current.value;

    const cicilanPokok = kredit / (jangka * 12);
    const bungaCalc = (kredit * bunga * 0.01) / 12;
    const cicilanJumlah = cicilanPokok + bungaCalc;

    setSimul(rupiah(cicilanJumlah));
  };

  return (
    <>
      <div className="prod-header container-fluid">
        <p>Beranda &gt; Simulasi Kredit</p>
        <h1>Simulasi Kredit</h1>
        <hr />
      </div>
      <div className="prod-desc">
        <h3>Yuk coba simulasikan kredit kamu!</h3>
        <div className="wrapper d-flex flex-column flex-lg-row">
          <div className="wrap-1">
            <form className="d-flex flex-column flex-md-row">
              <div className="form-wrap-1 row gy-2 me-md-3 overflow-hidden">
                <div className="col-12 col-md-4">
                  <label htmlFor="inputKredit" className="form-label">
                    Jumlah Kredit
                  </label>
                </div>
                <div className="col-12 col-md-8">
                  <input
                    type="number"
                    id="inputKredit"
                    className="form-control"
                    defaultValue={1000000}
                    ref={kreditRef}
                    onChange={(e) => kreditHandler(e)}
                    min={1000000}
                  />
                  {error !== "" && (
                    <div className="form-text text-danger">{error}</div>
                  )}
                </div>
                <div className="col-12 col-md-4">
                  <label htmlFor="inputJangka" className="form-label">
                    Jangka waktu (tahun)
                  </label>
                </div>
                <div className="col-12 col-md-8">
                  <select
                    id="inputJangka"
                    className="form-select"
                    defaultValue={1}
                    ref={jangkaRef}
                  >
                    <option value={1}>1</option>
                    <option value={1.5}>1.5</option>
                    <option value={2}>2</option>
                    <option value={2.5}>2.5</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                  </select>
                </div>
                <div className="col-12 col-md-4">
                  <label htmlFor="inputBunga" className="form-label">
                    Bunga (%)
                  </label>
                </div>
                <div className="col-12 col-md-8">
                  <input
                    type="number"
                    id="inputBunga"
                    className="form-control"
                    value={bunga}
                    disabled
                  />
                </div>
                <div className="col-md-4"></div>
                <div className="col-12 col-md-8">
                  <button
                    onClick={(e) => onSimulHandler(e)}
                    disabled={error === "" ? false : true}
                  >
                    Hitung
                  </button>
                </div>
                <div className="col-md-12"></div>
                <div className="col-md-12"></div>
              </div>
            </form>
          </div>
          {simul !== "" && (
            <div className="wrap-2 border p-4">
              <p>Angsuran per Bulan :</p>
              <h5>{simul}</h5>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
