import styles from "./Kontak.module.css";

// import placeholder from "../../assets/placeholder.png";
import phone from "../../assets/phone.svg";
import wa from "../../assets/whatsapp.svg";
import email from "../../assets/email.svg";
import fb from "../../assets/facebook.svg";
import ig from "../../assets/instagram.svg";

// HARDCODE UPDATE SOON
import cab1 from "../../assets/photos/Foto Kantor/Kantor Pusat.jpeg";
import cab2 from "../../assets/photos/Foto Kantor/Kantor Cabang Tangerang.jpg";
import cab3 from "../../assets/photos/Foto Kantor/Kantor Cab. Bekasi1.jpg";

export default function Kontak() {
  return (
    <>
      <div className={`${styles.kontakHead}`}>
        <p>Beranda &gt; Kontak</p>
        <h1>Kontak</h1>
        <hr />
      </div>
      <div className={`${styles.kontakBody}`}>
        <div className="lokasi row">
          <div className="col-12">
            <h2>Lokasi Kantor Kami</h2>
            <hr />
          </div>
          <div className="col-12 col-md-4 my-4">
            <img src={cab1} className={`${styles.location}`} alt="" />
            <h5>Kantor Pusat Depok</h5>
            <p>
              Ruko Depok Maharaja Blok A1 No.9-10
              <br />
              Jl. Raya Sawangan Pancoran Mas
              <br />
              Depok 16433
            </p>
            <div className={`${styles.info}`}>
              <div className="d-flex align-items-center phone">
                <img src={phone} alt="phone" />
                <p>(021) 77880066</p>
              </div>
              <div className="d-flex align-items-center wa">
                <img src={wa} alt="whatsapp" />
                <p>0812-1173-8784</p>
              </div>
              <div className="d-flex align-items-center email">
                <img src={email} alt="email" />
                <p>cs@bprags.co.id</p>
              </div>
              <div className="d-flex align-items-center fb">
                <img src={fb} alt="fb" />
                <p>BPR Arthaguna Sejahtera</p>
              </div>
              <div className="d-flex align-items-center ig">
                <img src={ig} alt="ig" />
                <p>@bpragsofficial</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4 my-4">
            <img src={cab2} className={`${styles.location}`} alt="" />
            <h5>Kantor Cabang Tangerang</h5>
            <p>
              Ruko Victoria Park Blok A2 No.20
              <br />
              Jl. Imam Bonjol, Karawaci Kota
              <br />
              Tangerang 15115
            </p>
            <div className={`${styles.info}`}>
              <div className="d-flex align-items-center phone">
                <img src={phone} alt="phone" />
                <p>(021) 55738722</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4 my-4">
            <img src={cab3} className={`${styles.location}`} alt="" />
            <h5>Kantor Cabang Bekasi</h5>
            <p>
              Ruko Metro Boulevard Blok A3A
              <br />
              Jl. Niaga Raya II, Jababeka II Cikarang Selatan
              <br />
              Kab. Bekasi 17530
            </p>
            <div className={`${styles.info}`}>
              <div className="d-flex align-items-center phone">
                <img src={phone} alt="phone" />
                <p>(021) 898830623</p>
              </div>
            </div>
          </div>
        </div>
        {/* <div className={`${styles.form} row`}>
          <h2>Kontak Kami</h2>
          <hr />
          <form className="row">
            <div className="col-6">
              <div className="row gy-3">
                <div className="col-4">
                  <p>Nama</p>
                </div>
                <div className="col-8">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Tuliskan nama lengkap anda"
                    id="nama"
                  />
                </div>
                <div className="col-4">
                  <p>Email</p>
                </div>
                <div className="col-8">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Tuliskan email anda"
                    id="email"
                  />
                </div>
                <div className="col-4">
                  <p>Subjek</p>
                </div>
                <div className="col-8">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Tuliskan subjek pesan anda"
                    id="subjek"
                  />
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="row gy-3">
                <div className="col-4">
                  <p>Pesan Anda</p>
                </div>
                <div className="col-8">
                  <textarea
                    className="form-control"
                    id="pesan"
                    placeholder="Tuliskan hal yang ingin anda sampaikan"
                  />
                </div>
                <div className="col-4"></div>
                <div className="col-8">
                  <button>Kirim</button>
                </div>
              </div>
            </div>
          </form>
        </div> */}
      </div>
    </>
  );
}
