import styles from "./Produk.module.css";
export default function Tabungan() {
  return (
    <>
      <div className={`${styles.prodHeader} container-fluid`}>
        <p>Produk &gt; Tabungan</p>
        <h1>Tabungan</h1>
        <hr />
      </div>
      <div
        className={`${styles.prodDesc} d-flex flex-column flex-lg-row justify-content-lg-evenly`}
      >
        <div className={`${styles.wrap1}`}>
          <p>
            Menurut <b>Undang-undang No 10 Tahun 1998</b> Tentang Perbankan,{" "}
            <b>Tabungan</b> adalah simpanan yang penarikannya hanya dapat
            dilakukan menurut syarat tertentu yang disepakati. <br />
            <br />
          </p>
          <h4>Produk Tabungan PT BPR Arthaguna Sejahtera</h4>
          <hr />
          <p>
            <b>1.Tabungan Sehati</b>
            <br />
            Tabungan untuk umum,
            <br />
          </p>
          <p>
            <b>2.Tabungan Tauladan</b>
            <br />
            Tabungan untuk Gurum pembukaan tabungannya dengan melampirkan Surat
            Keterangan Kerja sebagai Guru <br />
          </p>
          <p>
            <b>3.Tabungan Sipintar</b>
            <br />
            Tabungan untuk para pelajar, pembukaan tabungannya melampirkan Kartu
            Pelajar/Kartu Mahasiswa/Surat Keterangan dari Sekolah dan atau atas
            nama murid dengan identitas orang tua. <br />
          </p>
          <p>
            <b>4.Tabungan Pensiun</b>
            <br />
            Tabungan untuk nasabah yang mendapat fasilitas kredit dari PT BPR
            Arthaguna Sejahtera, yang besarnya ditentukan dalam perjanjian,
            yaitu sebesar minimal 1 X angsuran dan merupakan jaminan tunai [Cash
            Collateral]
            <br />
          </p>
          <p>
            Calon penabung melampirkan data berupa foto copy KTP/Kartu
            Pelajar/Kartu Mahasiswa dan mengisi formulir pendukung sesuai dengan
            ketentuan yang ditetapkan, selain diberikan bunga atas tabungan,
            terdapat biaya administrasi bank juga terdapat biaya pajak atas
            ketentuan yang telah ditetapkan pemerintah.
            <br /> <br />
          </p>
        </div>
        <div className={`${styles.wrap2}`}>
          <p>
            Menabung di BPR Arthaguna Sejahtera sangatlah mudah dan aman ;
            dijamin oleh LPS.
          </p>
          <ul>
            <li>Suku bunga bersaing</li>
            <li>Setoran awal Rp. 10.000,-</li>
            <li>Saldo minimum Rp. 5.000,-</li>
            <li>Biaya administrasi Rp.2.000,-</li>
            <li>Biaya pajak sesuai dengan ketentuan yang berlaku</li>
            <li>Biaya penutupan tabungan Rp.5.000,-</li>
          </ul>
          <p>
            Khusus untuk jenis Tabungan Pensiun, tidak dikenakan biaya
            administrasi bank dan tidak diberikan bunga.
          </p>
        </div>
      </div>
    </>
  );
}
