import Header from "./Header";
import Footer from "./Footer";
import { Outlet } from "react-router-dom";
import { motion } from "framer-motion";

export default function Layout() {
  const animation = {
    initial: {
      opacity: 0,
    },
    final: {
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
  };
  return (
    <>
      <Header />
      <motion.div
        variants={animation}
        initial="initial"
        animate="final"
        className="content"
      >
        <Outlet />
      </motion.div>
      <Footer />
    </>
  );
}
