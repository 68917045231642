import { useLocation, Route, Routes, Navigate } from "react-router-dom";

import Layout from "./components/Layout";

import Home from "./pages/Home";
import Tabungan from "./pages/produk/Tabungan";
import Kmas from "./pages/produk/Kmas";
import Deposito from "./pages/produk/Deposito";
import Simulasi from "./pages/simulasi/Simulasi";
import Pengertian from "./pages/tentang/Pengertian";
import Sejarah from "./pages/tentang/Sejarah";
import VisiMisi from "./pages/tentang/VisiMisi";
import Testimoni from "./pages/tentang/Testimoni";
import Berita from "./pages/berita/Berita";
import BeritaDetail from "./pages/berita/BeritaDetail";
import Galeri from "./pages/galeri/Galeri";
import GaleriDetail from "./pages/galeri/GaleriDetail";
import Kontak from "./pages/kontak/Kontak";
import Karir from "./pages/karir/Karir";
import KarirDetail from "./pages/karir/KarirDetail";
import Penghargaan from "./pages/tentang/Penghargaan";
import Panel from "./pages/admin/Panel";
import Login from "./pages/admin/Login";
import AddBerita from "./pages/admin/AddBerita";
import AddGallery from "./pages/admin/AddGallery";
import AddPenghargaan from "./pages/admin/AddPenghargaan";
import AddSlideshow from "./pages/admin/AddSlideshow";
import Persyaratan from "./pages/persyaratan/Persyaratan";
import Publikasi from "./pages/publikasi/Publikasi";

export default function RoutesWithAnimation() {
  const location = useLocation();

  return (
    <Routes location={location} key={location.key}>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="produk">
          <Route path="tabungan" element={<Tabungan />} />
          <Route path="k-mas" element={<Kmas />} />
          <Route path="deposito" element={<Deposito />} />
        </Route>
        <Route path="simulasi" element={<Simulasi />} />
        <Route path="tentang">
          <Route path="pengertian" element={<Pengertian />} />
          <Route path="sejarah" element={<Sejarah />} />
          <Route path="visi-misi" element={<VisiMisi />} />
          <Route path="testimoni" element={<Testimoni />} />
          <Route path="penghargaan" element={<Penghargaan />} />
        </Route>
        <Route path="berita">
          <Route index element={<Berita />} />
          <Route path=":id" element={<BeritaDetail />} />
        </Route>
        <Route path="galeri">
          <Route index element={<Galeri />} />
          <Route path=":id" element={<GaleriDetail />} />
        </Route>
        <Route path="kontak" element={<Kontak />} />
        <Route path="karir">
          <Route index element={<Karir />} />
          <Route path=":id" element={<KarirDetail />} />
        </Route>
        <Route path="admin">
          <Route index element={<Panel />} />
          <Route path="login" element={<Login />} />
          <Route path="berita" element={<AddBerita />} />
          <Route path="gallery" element={<AddGallery />} />
          <Route path="penghargaan" element={<AddPenghargaan />} />
          <Route path="slideshow" element={<AddSlideshow />} />
        </Route>
        <Route path="persyaratan" element={<Persyaratan />} />
        <Route path="publikasi" element={<Publikasi />} />\
        <Route path="*" element={<Navigate to="/" />} />
      </Route>
    </Routes>
  );
}
