export default function AddSlideshow() {
  return (
    <>
      <div className="d-flex flex-column align-items-center">
        <h1>Add Penghargaan</h1>
        <form className="d-flex flex-column flex-md-row">
          <div className="form row gy-3">
            <div className="col-12 ">
              <label htmlFor="inputTahun" className="form-label">
                Tahun
              </label>
              <input type="number" id="inputTahun" className="form-control" />
            </div>
            <div className="col-12 ">
              <label htmlFor="inputNama" className="form-label">
                Nama Penghargaan
              </label>
              <input type="text" id="inputNama" className="form-control" />
            </div>
            <div className="col-12 ">
              <label htmlFor="inputDesc" className="form-label">
                Deskripsi Penghargaan
              </label>
              <textarea id="inputDesc" className="form-control" />
            </div>
            <div className="col-12 ">
              <label htmlFor="inputFoto" className="form-label">
                Foto
              </label>
              <input type="file" id="inputFoto" className="form-control" />
            </div>
            <div className="col-12 d-flex justify-content-center">
              <button>Submit</button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
